import { StoreState } from "../reducer";
import { makeGlobalizeFunction } from "../utils";
import { Recipe } from "../../recipe";
import { FIELD_TYPES } from "./actionTypes";

const globalize = makeGlobalizeFunction((state: StoreState) => state.editState);

export const getEditableRecipe = globalize(
    (state): Recipe => {
        return state.recipe;
    }
);

export const getEditableField = globalize((state, fieldType: FIELD_TYPES): number | undefined => {
    if (fieldType === state.selectedField.field) {
        return state.selectedField.index;
    } else {
        return undefined;
    }
});
