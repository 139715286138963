import * as React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { selectors, StoreState } from "../../state";

interface OwnProps {
    toggleExpanded: () => void;
}

interface StateProps {
    searchValue: string;
    recipeNames: string[];
}

type SearchProps = StateProps;

const mapStateToProps = (state: StoreState): SearchProps => {
    const searchValue = selectors.getSearchValue(state);
    const recipeNames = selectors.getAllRecipeNames(state);
    return {
        searchValue,
        recipeNames,
    };
};

export const SearchResults: React.FC<StateProps & OwnProps> = props => {
    if (props.searchValue === "") {
        return null;
    } else {
        return (
            <ul className="search__results-list">
                {props.recipeNames
                    .filter(recipe => {
                        return recipe.toLowerCase().includes(props.searchValue.toLowerCase());
                    })
                    .map(result => (
                        <li className="search__result-item" key={result}>
                            <Link to={`/resepti/${result}`} onClick={props.toggleExpanded}>
                                {result}
                            </Link>
                        </li>
                    ))}
            </ul>
        );
    }
};

export const ConnectedSearchResults = connect(
    mapStateToProps,
    undefined
)(SearchResults);
