import { UnitName } from "../../utils/unitChanger";
import {
    PORTIONS_MULTIPLIER_SET,
    SET_EDITABLE_INDEX,
    RESET_EDIT_MODE,
    ADD_FILTER,
    REMOVE_FILTER,
    RESET_FILTERS,
    SET_UNIT,
    RESET_UNITS,
} from "./actionTypes";

export const setPortionsMultiplier = (multiplier: number) => ({
    type: PORTIONS_MULTIPLIER_SET,
    payload: {
        multiplier: multiplier,
    },
});

export const setEditableIndex = (index: number) => ({
    type: SET_EDITABLE_INDEX,
    payload: {
        index,
    },
});

export const resetEditMode = () => ({
    type: RESET_EDIT_MODE,
    payload: {},
});

export const addFilter = (filter: string) => ({
    type: ADD_FILTER,
    payload: {
        filter,
    },
});

export const removeFilter = (filter: string) => ({
    type: REMOVE_FILTER,
    payload: {
        filter,
    },
});

export const resetFilters = () => ({
    type: RESET_FILTERS,
    payload: {},
});

export const setUnit = (index: number, unit: UnitName) => ({
    type: SET_UNIT,
    payload: {
        index,
        unit,
    },
});

export const resetUnits = (ingredientNumber: number) => ({
    type: RESET_UNITS,
    payload: {
        ingredientNumber,
    },
});
