import * as React from "react";
import { Ingredient } from "../../recipe";
import { MoveButtons, RemoveButton } from "./recipeEditing";
import { ValidatedInput } from "./validatedInput";
import { noSpecialChars } from "./FormValidators";
import { classNames } from "../../utils";
import { selectors, actions, store, StoreState } from "../../state";
import { IngredientFieldType } from "../../state/actions";
import { connect } from "react-redux";
import { FIELD_TYPES } from "../../state/editState/actionTypes";
import { Dispatch } from "redux";

interface StateProps {
    ingredients: Ingredient[];
    editableIndex: number | undefined;
}

interface DispatchProps {
    addIngredient: () => void;
    addSubtitle: () => void;
    setIngredient: (index: number, field: IngredientFieldType, value: string) => void;
    moveIngredientUp: (index: number) => void;
    moveIngredientDown: (index: number) => void;
    setEditableIndex: (index: number) => void;
    removeIngredient: (index: number) => void;
}

export type IngredientListFormProps = StateProps & DispatchProps;

const mapStateToProps = (state: StoreState): StateProps => {
    const recipe = selectors.getEditableRecipe(state);
    const editableIndex = selectors.getEditableField(state, FIELD_TYPES.INGREDIENTS);
    return {
        ingredients: recipe.ingredients,
        editableIndex,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {
        addIngredient: () => dispatch(actions.addIngredient()),
        addSubtitle: () => dispatch(actions.addSubtitle()),
        setIngredient: (index, field, value) =>
            dispatch(actions.setIngredient(index, field, value)),
        moveIngredientUp: index => {
            dispatch(actions.moveIngredientUp(index));
            dispatch(actions.setEditableField(Math.max(0, index - 1), FIELD_TYPES.INGREDIENTS));
        },
        moveIngredientDown: index => {
            dispatch(actions.moveIngredientDown(index));
            const ingredientsLenght = selectors.getEditableRecipe(store.getState()).ingredients
                .length;
            dispatch(
                actions.setEditableField(
                    Math.min(ingredientsLenght - 1, index + 1),
                    FIELD_TYPES.INGREDIENTS
                )
            );
        },
        setEditableIndex: index =>
            dispatch(actions.setEditableField(index, FIELD_TYPES.INGREDIENTS)),
        removeIngredient: index => dispatch(actions.removeIngredient(index)),
    };
};
export const IngredientListForm: React.FC<IngredientListFormProps> = props => {
    return (
        <div className="recipeform-group ingredientlist">
            <div className="recipeform-group__header">Ainekset:</div>
            <IngredientLabels />
            {props.ingredients.map((ingredient, index) => (
                <IngredientListItem
                    ingredient={ingredient}
                    index={index}
                    valueChangeHandler={props.setIngredient}
                    removeIngredient={props.removeIngredient}
                    moveIngredientUp={props.moveIngredientUp}
                    moveIngredientDown={props.moveIngredientDown}
                    addIngredient={props.addIngredient}
                    rowSelected={props.setEditableIndex}
                    selectedRow={props.editableIndex}
                    key={index}
                />
            ))}
            <div className="recipeform__ingredient-buttons">
                <button className="recipeform-add-button" type="button" onClick={props.addSubtitle}>
                    Lisää alaotsikko
                </button>
                <button
                    className="recipeform-add-button"
                    type="button"
                    onClick={props.addIngredient}
                >
                    Lisää aines
                </button>
            </div>
        </div>
    );
};

export const ConnectedIngredientListForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(IngredientListForm);

export interface IngredientListProps {
    ingredient: Ingredient;
    index: number;
    valueChangeHandler: (index: number, field: IngredientFieldType, value: string) => void;
    removeIngredient: (index: number) => void;
    moveIngredientUp: (index: number) => void;
    moveIngredientDown: (index: number) => void;
    addIngredient: () => void;
    rowSelected: (index: number | undefined) => void;
    selectedRow: number | undefined;
}

const IngredientListItem: React.FC<IngredientListProps> = props => {
    const isSelected = props.index === props.selectedRow;

    if (props.ingredient.subtitle !== undefined) {
        return (
            <IngredientFormRow
                isSelected={isSelected}
                index={props.index}
                rowSelected={props.rowSelected}
                moveIngredientUp={props.moveIngredientUp}
                moveIngredientDown={props.moveIngredientDown}
                removeIngredient={props.removeIngredient}
            >
                <IngredientInput
                    index={props.index}
                    field="subtitle"
                    value={props.ingredient.subtitle}
                    valueChangeHandler={props.valueChangeHandler}
                    onKeyDown={event => {
                        if (event.key === "Enter") {
                            props.addIngredient();
                        }
                    }}
                    placeholder="Alaotsikko"
                />
            </IngredientFormRow>
        );
    } else {
        return (
            <IngredientFormRow
                isSelected={isSelected}
                index={props.index}
                rowSelected={props.rowSelected}
                moveIngredientUp={props.moveIngredientUp}
                moveIngredientDown={props.moveIngredientDown}
                removeIngredient={props.removeIngredient}
            >
                <IngredientInput
                    index={props.index}
                    field="quantity"
                    value={props.ingredient.quantity}
                    valueChangeHandler={props.valueChangeHandler}
                    placeholder=""
                />
                <IngredientInput
                    index={props.index}
                    field="unit"
                    value={props.ingredient.unit}
                    valueChangeHandler={props.valueChangeHandler}
                    placeholder=""
                />
                <IngredientInput
                    index={props.index}
                    field="name"
                    value={props.ingredient.name}
                    valueChangeHandler={props.valueChangeHandler}
                    onKeyDown={event => {
                        if (event.key === "Enter") {
                            props.addIngredient();
                        }
                    }}
                    placeholder=""
                />
            </IngredientFormRow>
        );
    }
};

export const IngredientFormRow: React.FC<{
    isSelected: boolean;
    index: number;
    rowSelected: (index: number) => void;
    moveIngredientUp: (index: number) => void;
    moveIngredientDown: (index: number) => void;
    removeIngredient: (index: number) => void;
}> = props => {
    const ingredientClasses = classNames({
        "ingredient-block": true,
        "ingredient-block--selected": props.isSelected,
    });
    return (
        <div
            className={ingredientClasses}
            key={props.index}
            tabIndex={-1}
            onFocus={() => {
                props.rowSelected(props.index);
            }}
        >
            {props.isSelected && (
                <MoveButtons
                    index={props.index}
                    moveUpFunction={props.moveIngredientUp}
                    moveDownFunction={props.moveIngredientDown}
                />
            )}
            <div className="ingredient-row">{props.children}</div>
            {props.isSelected && (
                <RemoveButton removeFunction={() => props.removeIngredient(props.index)} />
            )}
        </div>
    );
};

const IngredientLabels: React.FC<{}> = () => (
    <div className="ingredient-label__block">
        <IngredientLabel>Määrä:</IngredientLabel>
        <IngredientLabel>Yksikkö:</IngredientLabel>
        <IngredientLabel>Nimi:</IngredientLabel>
    </div>
);

const IngredientLabel: React.FC<{}> = props => (
    <div className="ingredient-label">{props.children}</div>
);

const IngredientInput: React.FC<{
    index: number;
    field: string;
    value: string | number;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    placeholder: string;
    valueChangeHandler: (index: number, field: string, value: string) => void;
}> = props => (
    <div className="recipeform-block">
        <ValidatedInput
            className="recipeform__input"
            onChange={event =>
                props.valueChangeHandler(
                    props.index,
                    props.field,
                    (event.target as HTMLInputElement).value
                )
            }
            value={props.value}
            validators={[noSpecialChars]}
            onKeyDown={props.onKeyDown}
            placeholder={props.placeholder}
        />
    </div>
);
