import * as React from "react";
import { HashRouter, Route, Routes, useParams } from "react-router-dom";
import { ConnectedMenu } from "./components/menu/menu";
import { ConnectedCategoryList } from "./components/listViews/categoryList";
import { ConnectedRecipeList } from "./components/listViews/recipeList";
import { ConnectedRecipeEditing } from "./components/recipeEditing/recipeEditing";
import { ConnectedManageRecipes } from "./components/listViews/manageRecipesView";
import { ConnectedPrivateRoute } from "./utils/PrivateRoute";
import { LandingPage } from "./components/landingPage/landingPage";
import { showErrorDialog } from "./commonComponents/modalDialog";
import { Provider } from "react-redux";
import { store } from "./state";
import { getRecipes } from "./services/recipeservice";
import { setAllRecipes } from "./state/actions";
import { ConnectedRecipeView } from "./components/recipeView/RecipeView";
import { ConnectedManageIngredients } from "./components/AdminTools/ManageIngredients";
import { initializeColorTheme } from "./utils/colorThemeUtils";
import { ConnectedSearch } from "./components/menu/search";

initializeColorTheme();

window.onerror = (msg) => {
    console.error(msg);
    showErrorDialog({ header: "Tuntematon virhe", message: msg.toString() });
};
getRecipes().then((recipes) => {
    store.dispatch(setAllRecipes(recipes));
});

export const App: React.FC<{}> = () => (
    <HashRouter>
        <div className="main-content">
            <Provider store={store}>
                <ConnectedMenu />
                <ConnectedSearch />
                <div className="content-container">
                    <Routes>
                        <Route path="/" element={<LandingPage />} />
                        <Route path="/reseptit" element={<ConnectedCategoryList />} />
                        <Route path="/reseptit/:category" element={<RecipeListComponent />} />
                        <Route path="/resepti/:recipeName" element={<RecipeViewComponent />} />
                        <Route
                            path="/reseptin-muokkaus/:recipeName"
                            element={<RecipeEditingComponent />}
                        />
                        <Route path="/ainekset" element={<ConnectedManageIngredients />} />
                        <Route
                            path="/hallitse"
                            element={<ConnectedPrivateRoute element={<ConnectedManageRecipes />} />}
                        />
                    </Routes>
                </div>
            </Provider>
        </div>
    </HashRouter>
);

const RecipeListComponent: React.FC<{}> = () => {
    const params = useParams();

    return <ConnectedRecipeList category={params.category} />;
};

const RecipeViewComponent: React.FC<{}> = () => {
    const params = useParams();
    return <ConnectedRecipeView recipeName={params.recipeName} />;
};

const RecipeEditingComponent: React.FC<{}> = () => {
    const params = useParams();
    return <ConnectedRecipeEditing recipeName={params.recipeName} />;
};
