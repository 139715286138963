import { createStore } from "redux";
import { applicationState } from "./reducer";
import * as stateActions from "./actions";
import * as stateSelectors from "./selectors";
export const actions = stateActions;
export const selectors = stateSelectors;
export { StoreState } from "./reducer";

export const store = createStore(
    applicationState,
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
