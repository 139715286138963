import * as React from "react";
import { CATEGORIES } from "../../constants";
import { noSpecialChars, notEmpty, isNumeric, isPositive } from "./FormValidators";
import { ValidatedInput } from "./validatedInput";
import { getEditableRecipe } from "../../state/selectors";
import { store, actions, StoreState } from "../../state";
import { connect } from "react-redux";
import { handleInputChange } from "../../utils";

interface StateProps {
    name: string;
    category: string;
    cookingTime: number;
    portions: number;
}

interface DispatchProps {
    setName: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    setCategory: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    setCookingTime: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    setPortions: (event: React.KeyboardEvent<HTMLInputElement>) => void;
}

const mapStateToProps = (state: StoreState): StateProps => {
    const recipe = getEditableRecipe(state);
    return {
        name: recipe.name,
        category: recipe.category,
        cookingTime: recipe.cookingTime,
        portions: recipe.portions,
    };
};

const mapDispatchToProps = (): DispatchProps => {
    return {
        setName: event =>
            handleInputChange(event, name => store.dispatch(actions.setRecipeName(name as string))),
        setCategory: event =>
            handleInputChange(event, category =>
                store.dispatch(actions.setCategory(category as string))
            ),
        setCookingTime: event =>
            handleInputChange(event, cookingTime =>
                store.dispatch(actions.setCoookingTime(cookingTime as number))
            ),
        setPortions: event =>
            handleInputChange(event, portions =>
                store.dispatch(actions.setPortions(portions as number))
            ),
    };
};

export type BasicInfoFormProps = StateProps & DispatchProps;

export const BasicInfoForm: React.FC<BasicInfoFormProps> = props => {
    return (
        <div className="recipeform-group recipeform_basic-info">
            <label className="recipeform-block">
                Reseptin nimi:
                <ValidatedInput
                    className={"recipeform__input"}
                    value={props.name}
                    onChange={props.setName}
                    validators={[noSpecialChars, notEmpty]}
                />
            </label>

            <label className="recipeform-block">
                Kategoria:
                <select
                    className="recipeform__input category-options"
                    value={props.category}
                    onChange={props.setCategory}
                >
                    <CategoryOptions />
                </select>
            </label>

            <label className="recipeform-block">
                Valmistusaika tunteina:
                <ValidatedInput
                    className="recipeform__input"
                    type="number"
                    value={props.cookingTime}
                    onChange={props.setCookingTime}
                    validators={[notEmpty, isNumeric, isPositive]}
                />
            </label>

            <label className="recipeform-block">
                Annokset:
                <ValidatedInput
                    className="recipeform__input"
                    type="number"
                    value={props.portions}
                    onChange={props.setPortions}
                    validators={[notEmpty, isNumeric, isPositive]}
                />
            </label>
        </div>
    );
};

export const ConnectedBasicInfoForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(BasicInfoForm);

function CategoryOptions(): any {
    return CATEGORIES.map(category => {
        return <option key={category}>{category}</option>;
    });
}
