import * as React from "react";
// @ts-ignore
import * as SearchIcon from "../../../assets/icon-search.inline.svg";
import { ConnectedNavigationIcon, ConnectedNavigationLinks } from "./navigationLinks";
import { ConnectedTabs } from "./tabs";
import { store, actions, selectors, StoreState } from "../../state";
import { connect } from "react-redux";

interface StateProps {
    isSearchExpanded: boolean;
}

interface DispachProps {
    expandSearch: () => void;
}

type MenuProps = StateProps & DispachProps;

export const Menu: React.FC<MenuProps> = (props) => {
    return (
        <header className="">
            <nav className="navigation-bar">
                <ConnectedNavigationIcon />
                <ConnectedNavigationLinks />
                <ConnectedTabs />
                <div className="icon search__icon" onClick={props.expandSearch}>
                    <SearchIcon />
                </div>
            </nav>
        </header>
    );
};

const mapStateToProps = (state: StoreState): StateProps => {
    const isSearchExpanded = selectors.isSearchExpanded(state);
    return {
        isSearchExpanded,
    };
};

const mapDispatchToProps = (): DispachProps => ({
    expandSearch: () => {
        store.dispatch(actions.expandSearch());
    },
});

export const ConnectedMenu = connect(mapStateToProps, mapDispatchToProps)(Menu);
