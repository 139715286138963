import * as React from "react";

//@ts-ignore
import * as CloseIcon from "../../../assets/icon-close.inline.svg";
//@ts-ignore
import * as MenuIcon from "../../../assets/icon-menu.inline.svg";
//@ts-ignore
import * as Logo from "../../../assets/icon-main.inline.svg";
//@ts-ignore
import * as RecipesIcon from "../../../assets/icon-cutlery.inline.svg";
//@ts-ignore
import * as AddRecipeIcon from "../../../assets/icon-plus.inline.svg";
//@ts-ignore
import * as EditRecipesIcon from "../../../assets/icon-edit.inline.svg";
import { ColorThemePicker } from "./colorThemePicker";
import { actions, selectors, store, StoreState } from "../../state";
import { connect } from "react-redux";

const NAVIGATION_LINKS = [
    {
        name: "Etusivu",
        icon: Logo,
        url: "#/",
    },
    {
        name: "Reseptit",
        icon: RecipesIcon,
        url: "#/reseptit",
    },
    {
        name: "Uusi resepti",
        icon: AddRecipeIcon,
        url: "#/reseptin-muokkaus/uusi",
    },
    {
        name: "Hallitse reseptejä",
        icon: EditRecipesIcon,
        url: "#/hallitse",
    },
];

interface StateProps {
    isExpanded: boolean;
}

interface OwnProps {}

interface DispachProps {
    expandMenu: () => void;
    collapseMenu: () => void;
}

type MenuProps = StateProps & OwnProps & DispachProps;

const mapStateToProps = (state: StoreState): StateProps => {
    const isExpanded = selectors.isMenuExpanded(state);
    return {
        isExpanded,
    };
};

const mapDispatchToProps = (): DispachProps => ({
    collapseMenu: () => store.dispatch(actions.collapseMenu()),
    expandMenu: () => store.dispatch(actions.expandMenu()),
});

export const NavigationIcon: React.FC<MenuProps> = (props) => (
    <div className="icon navigation-bar__menu-icon" onClick={props.expandMenu}>
        <MenuIcon />
    </div>
);

export const ConnectedNavigationIcon = connect(mapStateToProps, mapDispatchToProps)(NavigationIcon);

export const NavigationLinks: React.FC<MenuProps> = (props) => (
    <ul className={`navigation-bar__link-container ${props.isExpanded ? "expanded" : ""}`}>
        <li className="navigation-bar__close-icon" onClick={props.collapseMenu}>
            <CloseIcon />
        </li>
        {NAVIGATION_LINKS.map((link) => (
            <li onClick={props.collapseMenu} key={link.name}>
                <a className="navigation-bar__link" href={link.url} key={link.name}>
                    {link.name}
                </a>
            </li>
        ))}
        <ColorThemePicker />
    </ul>
);

export const ConnectedNavigationLinks = connect(
    mapStateToProps,
    mapDispatchToProps
)(NavigationLinks);
