import * as React from "react";
import { RoundToOneDecimal } from "../../utils/maths";

export const PortionControl: React.FC<{ portions: number; updatePortions: Function }> = props => {
    const handlePlusClick = () => {
        Number.isInteger(props.portions)
            ? props.updatePortions(props.portions + 1)
            : props.updatePortions(Math.ceil(props.portions));
    };
    const handleMinusClick = () => {
        Number.isInteger(props.portions)
            ? props.updatePortions(props.portions - 1)
            : props.updatePortions(Math.floor(props.portions));
    };

    return (
        <div className="portion-control__container">
            <label className="portion-control__portions-label">Annokset</label>
            <div onClick={handleMinusClick} className="portion-control__change-portions minus">
                -
            </div>
            <div className="portion-control__change-portions portion-control__portion-number">
                {RoundToOneDecimal(props.portions)}
            </div>
            <div onClick={handlePlusClick} className="portion-control__change-portions plus">
                +
            </div>
        </div>
    );
};
