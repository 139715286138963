import * as React from "react";
import { connect } from "react-redux";
import { selectors, StoreState } from "../../state";
import { makeInstructionNumberScaler } from "../../utils/scaleUtils";

interface OwnProps {
    recipeName: string;
}

interface StateProps {
    instructions: string[];
}

type InstructionListProps = OwnProps & StateProps;

export const InstructionList: React.FC<InstructionListProps> = (props) => {
    if (props.instructions) {
        return (
            <ol className="instructions-list__list">
                {props.instructions.map((instruction, i) => {
                    return (
                        <li className="instructions-list__instruction" key={i}>
                            {instruction}
                        </li>
                    );
                })}
            </ol>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
    const portionMultiplier = selectors.getPortionsMultiplier(state);
    const recipe = selectors.getRecipeByName(state, ownProps.recipeName);
    const instructions = recipe.instructions.map(makeInstructionNumberScaler(portionMultiplier));
    return {
        instructions,
    };
};

export const ConnectedInstructionList = connect(mapStateToProps)(InstructionList);
