import * as React from "react";
import { Link } from "react-router-dom";
import { LoadingSpinner } from "../../commonComponents/loadSpinner";
import { ConnectedRecipeFiltering } from "../../components/filtering/recipeFiltering";
import { selectors, StoreState } from "../../state";
import { connect } from "react-redux";

interface OwnProps {
    category: string;
}

interface StateProps {
    recipes: string[];
    isLoaded: boolean;
}

interface DispatchProps {}

type RecipeListProps = OwnProps & StateProps & DispatchProps;

function mapStateToProps(state: StoreState, ownProps: OwnProps): StateProps & OwnProps {
    const recipes = selectors.getRecipesWithCategory(state, ownProps.category);
    const isLoaded = selectors.isRecipesLoaded(state);
    return {
        recipes,
        isLoaded,
        ...ownProps,
    };
}

export const RecipeList: React.FC<RecipeListProps> = props => {
    if (props.category === "Kaikki") {
        return <ConnectedRecipeFiltering />;
    } else if (!props.isLoaded) {
        return <LoadingSpinner />;
    } else {
        return (
            <div>
                <div className="recipe-list__header">{props.category}</div>
                <ul className="recipe-list">
                    <RecipeListItems recipes={props.recipes} />
                </ul>
            </div>
        );
    }
};

export const ConnectedRecipeList = connect(
    mapStateToProps,
    undefined
)(RecipeList);

export const RecipeListItems: React.FC<{ recipes: string[] }> = props => (
    <>
        {props.recipes.map((recipe, i) => (
            <li className="recipelist-item" key={i}>
                <Link to={"/resepti/" + recipe} className="recipelist-item__link">
                    {recipe}
                </Link>
            </li>
        ))}
    </>
);
