interface Action {
    type: string;
    payload: any;
}

export function makeReducer<InitialStateType>(
    initialState: InitialStateType,
    actionHandlers: {
        [key: string]: (state: InitialStateType, payload: any) => InitialStateType;
    }
) {
    return (state: InitialStateType, action: Action) => {
        if (state === undefined) {
            return initialState;
        }
        const { type, payload } = action;
        const actionHandler = actionHandlers[type];
        if (actionHandler) {
            return actionHandler(state, payload);
        }
        return state;
    };
}

export const makeGlobalizeFunction = <Global, Local>(
    getLocalState: (globalState: Global) => Local
) => <SelectorArgs, Result>(selector: (localState: Local, args?: SelectorArgs) => Result) => (
    globalState: Global,
    args?: SelectorArgs
) => {
    return selector(getLocalState(globalState), args);
};
