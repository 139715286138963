import { combineReducers } from "redux";
import { editStateReducer } from "./editState";
import { viewStateReducer } from "./viewState";
import { recipesReducer } from "./recipes";
import { appStateReducer } from "./appState/reducer";

export const applicationState = combineReducers({
    editState: editStateReducer,
    viewState: viewStateReducer,
    recipes: recipesReducer,
    appState: appStateReducer,
});

export type StoreState = ReturnType<typeof applicationState>;
