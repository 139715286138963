import { getRecipeNames } from "../../services/recipeservice";

export async function ValidateName(recipeName: string, name: string) {
    if (name.length === 0) {
        return {
            isValid: false,
            message: "Reseptillä täytyy olla nimi",
        };
    }

    const allRecipes = await getRecipeNames();
    if (allRecipes.indexOf(name) !== -1 && recipeName !== name) {
        return {
            isValid: false,
            message: "Saman niminen resepti on jo olemassa",
        };
    }

    return {
        isValid: true,
        message: "",
    };
}

export interface ValidationResult {
    isValid: boolean;
    message: string;
}

function makeValidationResult(isValid: boolean, errorMessage: string): ValidationResult {
    return {
        isValid,
        message: isValid ? "" : errorMessage,
    };
}

export function noSpecialChars(name: string): ValidationResult {
    return makeValidationResult(!/("|'|`|´|;)/.test(name), "Ei saa sisältää erikoismerkkejä");
}

export function notEmpty(name: string): ValidationResult {
    return makeValidationResult(String(name).length > 0, "Kenttää ei voi jättää tyhjäksi");
}

export function isNumeric(input: string): ValidationResult {
    return makeValidationResult(/^-?[\d\,\.]*$/.test(input), "Arvon tulee olla numero");
}

export function isPositive(input: number): ValidationResult {
    return makeValidationResult(input >= 0, "Arvo ei voi olla alle 0");
}
