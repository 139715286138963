import { combineReducers } from "redux";
import { currentRecipeReducer } from "./currentRecipe";
import { makeReducer } from "../utils";
import { FIELD_TYPES, SET_EDITABLE_FIELD, RESET_EDITABLE_FIELD } from "./actionTypes";

const selectedFieldReducer = makeReducer(
    { index: undefined as number | undefined, field: undefined as FIELD_TYPES | undefined },
    {
        [SET_EDITABLE_FIELD]: (state, payload) => {
            const { index, field } = payload;
            return {
                index,
                field,
            };
        },
        [RESET_EDITABLE_FIELD]: (state, payload) => {
            return { index: undefined, field: undefined };
        },
    }
);

export const editStateReducer = combineReducers({
    recipe: currentRecipeReducer,
    selectedField: selectedFieldReducer,
});
