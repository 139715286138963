import * as React from "react";
import { Link } from "react-router-dom";
import { reduceIngredients } from "./utils/filteringFunctions";
import { Recipe } from "../../recipe";
import { filterRecipes } from "./utils/filteringFunctions";
import { LoadingSpinner } from "../../commonComponents/loadSpinner";

import { FilterInput } from "./filterInput";
import { FilterList } from "./filterList";
import { selectors, store, actions, StoreState } from "../../state";
import { connect } from "react-redux";

interface OwnProps {}

interface StateProps {
    recipes: Recipe[];
    ingredientList: string[];
    appliedFilters: string[];
    isLoaded: boolean;
}

interface DispatchProps {
    addFilter: (filter: string) => void;
    removeFilter: (filter: string) => () => void;
    clearFilters: (filter: string) => void;
}

export type RecipeFilteringProps = OwnProps & StateProps & DispatchProps;

const mapStateToProps = (state: StoreState): StateProps & OwnProps => {
    const recipes = selectors.getAllRecipes(state);
    const allIngredients = selectors.getAllIngredientNames(state);
    const ingredientList = reduceIngredients(allIngredients);
    const appliedFilters = selectors.getAppliedFilters(state);
    const isLoaded = selectors.isRecipesLoaded(state);

    return {
        recipes,
        ingredientList,
        appliedFilters,
        isLoaded,
    };
};

const mapDispatchToProps = (): DispatchProps => ({
    addFilter: filter => store.dispatch(actions.addFilter(filter)),
    removeFilter: filter => () => store.dispatch(actions.removeFilter(filter)),
    clearFilters: () => store.dispatch(actions.resetFilters()),
});

export const RecipeFiltering: React.FC<RecipeFilteringProps> = props => {
    if (props.isLoaded) {
        return (
            <div className="filter-content">
                <FilterList filters={props.appliedFilters} removeFilter={props.removeFilter} />
                <FilterInput ingredients={props.ingredientList} filterAdded={props.addFilter} />
                <div className="filtered-recipes">
                    <FilteredRecipes recipes={props.recipes} filters={props.appliedFilters} />
                </div>
            </div>
        );
    } else {
        return <LoadingSpinner />;
    }
};

export const ConnectedRecipeFiltering = connect(
    mapStateToProps,
    mapDispatchToProps
)(RecipeFiltering);

export function FilteredRecipes(props: { recipes: Recipe[]; filters: string[] }) {
    const filteredRecipes = filterRecipes(props.filters, props.recipes);

    return (
        <ul className="filtered-recipes__list">
            {filteredRecipes.length !== 0 ? (
                filteredRecipes.map(recipeName => (
                    <li className="filtered-recipes__list-item" key={recipeName}>
                        <Link to={"/resepti/" + recipeName} className="filtered-recipes__link">
                            {recipeName}
                        </Link>
                    </li>
                ))
            ) : (
                <div className="filtered-recipes__list--no-results">Ei reseptejä</div>
            )}
        </ul>
    );
}
