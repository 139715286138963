import * as React from "react";
import { MoveButtons } from "./recipeEditing";
import { RemoveButton } from "./recipeEditing";
import { ValidatedInput } from "./validatedInput";
import { noSpecialChars, notEmpty } from "./FormValidators";
import { classNames } from "../../utils";
import { getEditableRecipe, getEditableField } from "../../state/selectors";
import { store, actions, selectors, StoreState } from "../../state";
import { connect } from "react-redux";
import { FIELD_TYPES } from "../../state/editState/actionTypes";

interface StateProps {
    instructions: string[];
    editableIndex: number | undefined;
}

interface DispatchProps {
    setInstruction: (index: number, value: string) => void;
    removeInstruction: (index: number) => void;
    moveInstructionUp: (index: number) => void;
    moveInstructionDown: (index: number) => void;
    setSelectedInstruction: (index: number) => void;
    addInstruction: () => void;
}

export type InstructionListFormProps = StateProps & DispatchProps;

const mapStateToProps = (state: StoreState): StateProps => {
    const recipe = getEditableRecipe(state);
    const editableIndex = getEditableField(state, FIELD_TYPES.INSTRUCTIONS);
    return {
        instructions: recipe.instructions,
        editableIndex,
    };
};

const mapDispatchToProps = (): DispatchProps => {
    return {
        setInstruction: (index: number, value: string) =>
            store.dispatch(actions.setInstruction(index, value)),
        removeInstruction: (index) => store.dispatch(actions.removeInstruction(index)),
        moveInstructionUp: (index) => {
            store.dispatch(actions.moveInstructionUp(index));
            store.dispatch(
                actions.setEditableField(Math.max(0, index - 1), FIELD_TYPES.INSTRUCTIONS)
            );
        },
        moveInstructionDown: (index) => {
            store.dispatch(actions.moveInstructionDown(index));
            const instructionsLenght = selectors.getEditableRecipe(store.getState()).instructions
                .length;
            store.dispatch(
                actions.setEditableField(
                    Math.min(instructionsLenght - 1, index + 1),
                    FIELD_TYPES.INSTRUCTIONS
                )
            );
        },
        setSelectedInstruction: (index) =>
            store.dispatch(actions.setEditableField(index, FIELD_TYPES.INSTRUCTIONS)),
        addInstruction: () => store.dispatch(actions.addInstruction()),
    };
};

export const InstructionListForm: React.FC<InstructionListFormProps> = (props) => {
    return (
        <div className="recipeform-group ingredientlist">
            <div className="recipeform-group__header">Työvaiheet:</div>
            {props.instructions.map((instruction, index) => (
                <InstructionListItem
                    index={index}
                    instruction={instruction}
                    valueChangeHandler={props.setInstruction}
                    removeInstruction={props.removeInstruction}
                    moveInstructionUp={props.moveInstructionUp}
                    moveInstructionDown={props.moveInstructionDown}
                    addInstruction={props.addInstruction}
                    setSelectedInstruction={props.setSelectedInstruction}
                    selectedInstruction={props.editableIndex}
                    key={index}
                />
            ))}
            <button
                className="recipeform-add-button add-instruction"
                type="button"
                onClick={props.addInstruction}
            >
                Lisää työvaihe
            </button>
        </div>
    );
};

export const ConnectedInstructionListForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(InstructionListForm);

type InstructionListProps = {
    instruction: string;
    index: number;
    valueChangeHandler: any;
    removeInstruction: any;
    moveInstructionUp: any;
    moveInstructionDown: any;
    addInstruction: Function;
    setSelectedInstruction: (index: number) => void;
    selectedInstruction: number | undefined;
};

const InstructionListItem: React.FC<InstructionListProps> = (props) => {
    const isSelected = props.selectedInstruction === props.index;
    const instructionClasses = classNames({
        "instruction-block": true,
        "instruction-block--selected": isSelected,
    });
    return (
        <div
            className={instructionClasses}
            tabIndex={-1}
            onFocus={() => props.setSelectedInstruction(props.index)}
        >
            {isSelected && (
                <MoveButtons
                    index={props.index}
                    moveUpFunction={props.moveInstructionUp}
                    moveDownFunction={props.moveInstructionDown}
                />
            )}
            <div className="recipeform-instruction-group">
                <ValidatedInput
                    className="recipeform__input instruction-field"
                    onChange={(event) =>
                        props.valueChangeHandler(
                            props.index,
                            (event.target as HTMLInputElement).value
                        )
                    }
                    value={props.instruction}
                    onKeyDown={(event) => {
                        if (event.key === "Enter") {
                            props.addInstruction();
                        }
                    }}
                    validators={[noSpecialChars, notEmpty]}
                />
            </div>
            {isSelected && (
                <RemoveButton removeFunction={() => props.removeInstruction(props.index)} />
            )}
        </div>
    );
};
