import { makeReducer } from "../../utils";
import {
    SET_RECIPE_NAME,
    SET_CATEGORY,
    SET_COOKING_TIME,
    SET_DESCRIPTION,
    SET_PORTIONS,
    SET_RECIPE_PICTURE,
} from "./actionTypes";
import { Recipe } from "../../../recipe";
import { combineReducers } from "redux";
import { ingredientsReducer } from "./ingredients";
import { instructionsReducer } from "./instructions";
import { INITIALIZE_EDITABLE_RECIPE } from "../actionTypes";

const recipeNameReducer = makeReducer("", {
    [INITIALIZE_EDITABLE_RECIPE]: (state, payload: Recipe) => {
        return payload.name;
    },
    [SET_RECIPE_NAME]: (state, payload) => {
        return payload.name;
    },
});

const recipePictureReducer = makeReducer(null as string | null, {
    [INITIALIZE_EDITABLE_RECIPE]: (state, payload: Recipe) => {
        return payload.picture || null;
    },
    [SET_RECIPE_PICTURE]: (state, payload) => {
        return payload.picture;
    },
});

const categoryRecucer = makeReducer("muut", {
    [INITIALIZE_EDITABLE_RECIPE]: (state, payload: Recipe) => {
        return payload.category;
    },
    [SET_CATEGORY]: (state, payload) => {
        return payload.category;
    },
});

const portionsReducer = makeReducer(0, {
    [INITIALIZE_EDITABLE_RECIPE]: (state, payload: Recipe) => {
        return payload.portions;
    },
    [SET_PORTIONS]: (state, payload) => {
        return payload.portions;
    },
});

const cookingTimeReducer = makeReducer(0, {
    [INITIALIZE_EDITABLE_RECIPE]: (state, payload: Recipe) => {
        return payload.cookingTime;
    },
    [SET_COOKING_TIME]: (state, payload) => {
        return payload.cookingTime;
    },
});

const descriptionReducer = makeReducer("", {
    [INITIALIZE_EDITABLE_RECIPE]: (state, payload: Recipe) => {
        return payload.description || "";
    },
    [SET_DESCRIPTION]: (state, payload) => {
        return payload.description;
    },
});

export const currentRecipeReducer = combineReducers<Recipe>({
    ingredients: ingredientsReducer,
    name: recipeNameReducer,
    category: categoryRecucer,
    portions: portionsReducer,
    cookingTime: cookingTimeReducer,
    description: descriptionReducer,
    instructions: instructionsReducer,
    picture: recipePictureReducer,
});
