import { FIELD_TYPES, SET_EDITABLE_FIELD, RESET_EDITABLE_FIELD } from "./actionTypes";

export * from "./currentRecipe/actions";

export const setEditableField = (index: number, field: FIELD_TYPES) => ({
    type: SET_EDITABLE_FIELD,
    payload: {
        field,
        index,
    },
});

export const resetEditableField = () => ({
    type: RESET_EDITABLE_FIELD,
    payload: {},
});
