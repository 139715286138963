import * as React from "react";

export const FilterList: React.FC<{
    filters: string[];
    removeFilter: (filter: string) => () => void;
}> = ({ filters, removeFilter }) => {
    return (
        <div className="filter-list__container">
            {filters.map(filter => (
                <div className="filter-list__item" key={filter}>
                    {filter}
                    <div className="filter-list__remove" onClick={removeFilter(filter)}>
                        <div className="filter-list__remove-icon">x</div>
                    </div>
                </div>
            ))}
        </div>
    );
};
