/**
 * Created by Eero Saarinen (eero.saarinen@hotmail.fi)
 * Created under Creative Commons licence
 */

export class Recipe {
    name: string;
    category: string;
    portions: number;
    cookingTime: number;
    ingredients: Ingredient[];
    instructions: string[];
    description: string;
    picture: string | null;

    constructor() {
        this.name = "";
        this.category = "Muut";
        this.portions = 0;
        this.cookingTime = 0;
        this.ingredients = [];
        this.instructions = [];
        this.description = "";
        this.picture = null;
    }
}

export class Ingredient {
    name?: string;
    quantity?: number;
    unit?: string;
    subtitle?: string;
    prefix?: string;
    postfix?: string;

    constructor(isIngredient: Boolean) {
        if (!isIngredient) {
            this.subtitle = "";
        } else {
            this.name = "";
            this.quantity = 0;
            this.unit = "";
        }
    }
}

export function newSubtitle() {
    return new Ingredient(false);
}

export function newIngredient() {
    return new Ingredient(true);
}
