import { makeReducer } from "../utils";
import {
    ADD_TAB,
    REMOVE_TAB,
    SET_SEARCH_VALUE,
    SET_LOGGED_IN,
    SET_SEARCH_EXPANDED,
    SET_MENU_EXPANDED,
} from "./actionTypes";

const initialAppState = {
    tabs: [] as string[],
    searchValue: "",
    isLoggedIn: false,
    isSearchExpanded: false,
    isMenuExpanded: false,
};

export const appStateReducer = makeReducer(initialAppState, {
    [ADD_TAB]: (state, payload) => {
        return { ...state, tabs: state.tabs.concat(payload.name) };
    },
    [REMOVE_TAB]: (state, payload) => {
        return { ...state, tabs: state.tabs.filter((tab) => tab !== payload.name) };
    },
    [SET_SEARCH_VALUE]: (state, payload) => {
        return { ...state, searchValue: payload.value };
    },
    [SET_LOGGED_IN]: (state, payload) => {
        return { ...state, isLoggedIn: payload.value };
    },
    [SET_SEARCH_EXPANDED]: (state, payload) => {
        return { ...state, isSearchExpanded: payload.value };
    },
    [SET_MENU_EXPANDED]: (state, payload) => {
        return { ...state, isMenuExpanded: payload.value };
    },
});
