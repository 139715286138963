import {
    SET_INGREDIENTS,
    ADD_INGREDIENT,
    ADD_SUBTITLE,
    REMOVE_INGREDIENT,
    SET_INGREDIENT,
    MOVE_INGREDIENT_UP,
    MOVE_INGREDIENT_DOWN,
} from "./actionTypes";
import { Ingredient } from "../../../../recipe";

export type IngredientFieldType = "quantity" | "unit" | "name" | "subtitle";

export const setIngredients = (ingredients: Ingredient[]) => ({
    type: SET_INGREDIENTS,
    payload: {
        ingredients,
    },
});

export const setIngredient = (index: number, field: IngredientFieldType, value: string) => ({
    type: SET_INGREDIENT,
    payload: {
        index,
        field,
        value,
    },
});

export const moveIngredientUp = (index: number) => ({
    type: MOVE_INGREDIENT_UP,
    payload: {
        index,
    },
});

export const moveIngredientDown = (index: number) => ({
    type: MOVE_INGREDIENT_DOWN,
    payload: {
        index,
    },
});

export const addIngredient = () => ({
    type: ADD_INGREDIENT,
    payload: {},
});

export const addSubtitle = () => ({
    type: ADD_SUBTITLE,
    payload: {},
});

export const removeIngredient = (index: number) => ({
    type: REMOVE_INGREDIENT,
    payload: {
        index,
    },
});
