import * as React from "react";
import { putRecipe, editRecipe, deleteRecipe } from "../../services/recipeservice";
import { ConnectedBasicInfoForm } from "./BasicInfoForm";
import { ConnectedIngredientListForm } from "./IngredientListForm";
import { ConnectedInstructionListForm } from "./InstructionsListForm";
import { showModalDialog, showErrorDialog } from "../../commonComponents/modalDialog";
import { ValidateName } from "./FormValidators";

//@ts-ignore
import * as ArrowIcon from "../../../assets/icon-arrow.inline.svg";
import { store, selectors, StoreState } from "../../state";
import { resetEditableRecipe } from "../../state/operations";
import { getEditableRecipe } from "../../state/selectors";
import { LoadingSpinner } from "../../commonComponents/loadSpinner";
import { connect } from "react-redux";
import { Dispatch } from "redux";

interface OwnProps {
    recipeName: string;
}

interface StateProps {
    isRecipesLoaded: boolean;
}

interface DispatchProps {
    resetEditableRecipe: (name: string) => void;
}

export type RecipeEditingProps = OwnProps & StateProps & DispatchProps;

const mapStateToProps = (state: StoreState, ownProps: OwnProps): OwnProps & StateProps => {
    const isRecipesLoaded = selectors.isRecipesLoaded(state);
    return {
        isRecipesLoaded,
        ...ownProps,
    };
};

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {
        resetEditableRecipe: name => {
            resetEditableRecipe(dispatch, store.getState(), name);
        },
    };
};

export const RecipeEditing: React.FC<RecipeEditingProps> = props => {
    React.useEffect(() => {
        props.resetEditableRecipe(props.recipeName);
    }, [props.isRecipesLoaded, props.recipeName]);

    if (props.isRecipesLoaded) {
        return (
            <div>
                <div className="recipeform-header">
                    {props.recipeName === "uusi" ? "Lisää Resepti" : "Muokkaa reseptiä"}
                </div>
                <div className="recipeform">
                    <ConnectedBasicInfoForm />
                    <ConnectedIngredientListForm />
                    <ConnectedInstructionListForm />
                </div>
                <button
                    className="submitbutton submit"
                    onClick={() => {
                        onSubmit(props.recipeName);
                    }}
                >
                    {props.recipeName === "uusi" ? "Lisää" : "Tallenna"}
                </button>
                <button
                    className="submitbutton reset"
                    onClick={() => props.resetEditableRecipe(props.recipeName)}
                >
                    Peru muutokset
                </button>
                {props.recipeName !== "uusi" && (
                    <button
                        className="submitbutton delete"
                        onClick={() => onDeleteRecipe(props.recipeName)}
                    >
                        Poista Resepti
                    </button>
                )}
            </div>
        );
    } else {
        return <LoadingSpinner />;
    }
};

export const ConnectedRecipeEditing = connect(
    mapStateToProps,
    mapDispatchToProps
)(RecipeEditing);

export const MoveButtons: React.FC<{
    index: number;
    moveUpFunction: (index: number) => void;
    moveDownFunction: (index: number) => void;
}> = props => (
    <div className="movebuttons-container">
        <div className="icon movebutton moveup" onClick={() => props.moveUpFunction(props.index)}>
            <ArrowIcon />
        </div>
        <div
            className="icon movebutton movedown"
            onClick={() => props.moveDownFunction(props.index)}
        >
            <ArrowIcon />
        </div>
    </div>
);

export const RemoveButton: React.FC<{ removeFunction: any }> = props => (
    <div className="icon-container icon-remove" onClick={props.removeFunction}>
        X
    </div>
);

const validateRecipe = async (recipeName: string) => {
    if (document.getElementsByClassName("invalid").length !== 0) {
        return false;
    } else if (!(await ValidateName(recipeName, getEditableRecipe(store.getState()).name))) {
        return false;
    }
    return true;
};

const onSubmit = async (recipeName: string) => {
    const isFormValid = await validateRecipe(recipeName);

    if (!isFormValid) {
        showErrorDialog({
            header: "Virhe!",
            message: "Reseptissä on virheitä. tarkista resepti",
        });
    } else if (recipeName === "uusi") {
        putRecipe(getEditableRecipe(store.getState())).then(() => {
            showModalDialog({
                header: "Valmis",
                message: "Resepti lisätty onnistuneesti.",
                primaryAction: redirectToFrontpage,
            });
        });
    } else {
        editRecipe(getEditableRecipe(store.getState())).then(() => {
            showModalDialog({
                header: "Valmis",
                message: "Resepti päivitetty onnistuneesti.",
                primaryAction: redirectToFrontpage,
            });
        });
    }
};

const onDeleteRecipe = (recipeName: string) => {
    const confirmation = confirm("Haluatko varmasti poistaa tämän reseptin?");
    if (confirmation) {
        deleteRecipe(recipeName).then(() => {
            showModalDialog({
                header: "Valmis",
                message: "Resepti poistettu onnistuneesti.",
                primaryAction: redirectToFrontpage,
            });
        });
    }
};

const redirectToFrontpage = () => window.location.replace(window.location.origin);
