import { selectors, actions } from ".";
import { Recipe } from "../recipe";
import { initializeEditableRecipe } from "./actions";
import { StoreState } from "./reducer";
import { getRecipeByName } from "./selectors";
import { Dispatch } from "redux";

export const setPortionsMultiplierWithPortionsNumber = (
    dispatch: Dispatch,
    state: StoreState,
    recipeName: string,
    newPortions: number
) => {
    if (newPortions > 0) {
        const currentRecipe = selectors.getRecipeByName(state, recipeName);
        const recipePortions = currentRecipe.portions || 1;
        const portionMultiplier = newPortions !== 0 ? newPortions / recipePortions : 1;
        if (portionMultiplier !== selectors.getPortionsMultiplier(state)) {
            dispatch(actions.setPortionsMultiplier(portionMultiplier));
        }
    }
};

export const resetEditableRecipe = (dispatch: Dispatch, state: StoreState, recipeName: string) => {
    if (recipeName === "uusi") {
        dispatch(initializeEditableRecipe(new Recipe()));
    } else {
        dispatch(
            initializeEditableRecipe(JSON.parse(JSON.stringify(getRecipeByName(state, recipeName))))
        );
    }
};
