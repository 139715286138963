export const handleInputChange = (
    event: any,
    changeFunction: (input: string | number | boolean) => void
): void => {
    const target = event.target;
    let value: string | number | boolean =
        target.type === "checkbox" ? target.checked : target.value;
    if (typeof value === "string") {
        value = parseFloat(value) || value;
    }
    changeFunction(value);
};
