import * as React from "react";
import { Link } from "react-router-dom";
import { getFavorites } from "../../utils/favoriteUtils";
import { getHistory } from "../../utils/historyUtils";
//@ts-ignore
import * as Logo from "../../../assets/icon-main.inline.svg";

export const LandingPage: React.FC<{}> = () => {
    return (
        <>
            <LandingPageTitle />
            <div className="landing-page__container">
                <FavoriteList favorites={getFavorites()} />
                <HistoryList history={getHistory()} />
            </div>
        </>
    );
};

export const LandingPageTitle: React.FC<{}> = () => (
    <Link className="landing-page-title__container" to="/reseptit">
        <div className="landing-page-title__logo">
            <Logo />
        </div>
    </Link>
);

export const FavoriteList: React.FC<{ favorites: string[] }> = (props) => (
    <div className="landing-page-favorites__container">
        <h1 className="landing-page-favorites__title">Omat suosikit</h1>
        <ul className="landing-page-favorites__list">
            {props.favorites.length === 0 && (
                <div className="landing-page-favorites__list--no-content">Ei suosikkeja</div>
            )}
            {props.favorites.map((recipe) => (
                <li key={recipe} className="landing-page-favorites__item">
                    <Link className="landing-page-favorites__link" to={"/resepti/" + recipe}>
                        {recipe}
                    </Link>
                </li>
            ))}
        </ul>
    </div>
);

export const HistoryList: React.FC<{ history: string[] }> = (props) => (
    <div className="landing-page-history__container">
        <h1 className="landing-page-history__title">Viimeksi katsotut</h1>
        <ul className="landing-page-history__list">
            {props.history.length === 0 && (
                <div className="landing-page-history__list--no-content">
                    Ei viimeksi katsottuja reseptejä
                </div>
            )}
            {props.history.map((recipe) => (
                <li key={recipe} className="landing-page-history__item">
                    <Link className="landing-page-history__link" to={"/resepti/" + recipe}>
                        {recipe}
                    </Link>
                </li>
            ))}
        </ul>
    </div>
);
