import * as React from "react";

export interface FilterInputState {
    inputValue: string;
}

interface FilterInputProps {
    ingredients: string[];
    filterAdded: (filter: string) => any;
}

export const FilterInput: React.FC<FilterInputProps> = props => {
    const [inputValue, setInputValue] = React.useState("");

    return (
        <div className="filter-input__container">
            <input
                type="text"
                name="Filter"
                className="filter-input__input"
                placeholder="Syötä raaka-aine"
                value={inputValue}
                onChange={event => setInputValue(event.target.value)}
            />
            {inputValue.length !== 0 && (
                <div className="filter-input__list-container">
                    <ul className="filter-input__list">
                        {getSuggestions(props.ingredients, inputValue).map(suggestion => (
                            <li
                                key={suggestion}
                                className="filter-input__list-item"
                                onClick={() => {
                                    props.filterAdded(suggestion);
                                    setInputValue("");
                                }}
                            >
                                {suggestion}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export const getSuggestions = (ingredients: string[], searchValue: string): string[] => {
    return ingredients.filter(ingredient =>
        ingredient.toLowerCase().includes(searchValue.toLowerCase())
    );
};
