import * as React from "react";
import { classNames } from "../../utils";
import { isFavorite, toggleFavorite } from "../../utils/favoriteUtils";

export const FavoriteControl: React.FC<{ recipeName: string }> = props => {
    const [isFavoriteState, setFavoriteState] = React.useState(isFavorite(props.recipeName));
    const className = classNames({
        "recipe-favorite-control": true,
        "recipe-favorite-control--is-favorite": isFavoriteState,
    });

    return (
        <div
            className={className}
            onClick={() => {
                toggleFavorite(props.recipeName);
                setFavoriteState(!isFavoriteState);
            }}
        >
            {isFavoriteState ? "★" : "☆"}
        </div>
    );
};
