import * as React from "react";
import { Link } from "react-router-dom";
import { LoadingSpinner } from "../../commonComponents/loadSpinner";
import { selectors, StoreState } from "../../state";
import { connect } from "react-redux";

interface OwnProps {}

interface StateProps {
    categories: string[];
    isLoaded: boolean;
}

interface DispatchProps {}

type CategoryListProps = OwnProps & StateProps & DispatchProps;

function mapStateToProps(state: StoreState): StateProps & OwnProps {
    const categories = selectors.getCategories(state);
    const isLoaded = selectors.isRecipesLoaded(state);
    return {
        categories,
        isLoaded,
    };
}

export const CategoryList: React.FC<CategoryListProps> = props => {
    if (props.isLoaded) {
        return (
            <div>
                <div className="category-list__header">Kategoriat</div>
                <ul className="category-list">
                    <CategoryListItems categories={props.categories} />
                </ul>
            </div>
        );
    } else {
        return <LoadingSpinner />;
    }
};

export const ConnectedCategoryList = connect(
    mapStateToProps,
    undefined
)(CategoryList);

export const CategoryListItems: React.FC<{ categories: string[] }> = props => (
    <>
        {props.categories.map((category, i) => (
            <li className="category-item" key={i}>
                <Link to={"/reseptit/" + category} className="category-item__link">
                    {category}
                </Link>
            </li>
        ))}
    </>
);
