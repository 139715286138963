import { makeReducer } from "../../../utils";
import {
    SET_INGREDIENTS,
    ADD_INGREDIENT,
    ADD_SUBTITLE,
    REMOVE_INGREDIENT,
    SET_INGREDIENT,
    MOVE_INGREDIENT_UP,
    MOVE_INGREDIENT_DOWN,
} from "./actionTypes";
import { Ingredient, Recipe } from "../../../../recipe";
import { INITIALIZE_EDITABLE_RECIPE } from "../../actionTypes";

const initialState = [] as Ingredient[];

export const ingredientsReducer = makeReducer(initialState, {
    [INITIALIZE_EDITABLE_RECIPE]: (state, payload: Recipe) => {
        const { ingredients } = payload;
        return [...ingredients];
    },
    [SET_INGREDIENTS]: (state, payload) => {
        const { ingredients } = payload;
        return ingredients;
    },
    [SET_INGREDIENT]: (state, payload) => {
        const { index: editIndex, value, field } = payload;
        return state.map((ingredient, index) => {
            if (index === editIndex) {
                return { ...ingredient, [field]: value };
            } else {
                return { ...ingredient };
            }
        });
    },
    [ADD_INGREDIENT]: (state, payload) => {
        return [...state, new Ingredient(true)];
    },
    [ADD_SUBTITLE]: (state, payload) => {
        return [...state, new Ingredient(false)];
    },
    [REMOVE_INGREDIENT]: (state, payload) => {
        const { index: removeIndex } = payload;
        return state.filter((_, index) => index !== removeIndex);
    },
    [MOVE_INGREDIENT_UP]: (state, payload) => {
        const { index: moveIndex } = payload;
        if (moveIndex === 0) {
            return state;
        } else {
            return state.reduce(
                (newState, ingredient, index) => {
                    if (index === moveIndex - 1) {
                        return [...newState, state[moveIndex]];
                    } else if (index === moveIndex) {
                        return [...newState, state[moveIndex - 1]];
                    } else {
                        return [...newState, ingredient];
                    }
                },
                [] as Ingredient[]
            );
        }
    },
    [MOVE_INGREDIENT_DOWN]: (state, payload) => {
        const { index: moveIndex } = payload;
        if (moveIndex === state.length - 1) {
            return state;
        } else {
            return state.reduce(
                (newState, ingredient, index) => {
                    if (index === moveIndex + 1) {
                        return [...newState, state[moveIndex]];
                    } else if (index === moveIndex) {
                        return [...newState, state[moveIndex + 1]];
                    } else {
                        return [...newState, ingredient];
                    }
                },
                [] as Ingredient[]
            );
        }
    },
});
