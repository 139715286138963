import { makeGlobalizeFunction } from "../utils";
import { StoreState } from "../reducer";
import { Recipe } from "../../recipe";
import { BASIC_INGREDIENTS } from "../../components/filtering/utils/filteringConstants";

const globalize = makeGlobalizeFunction((state: StoreState) => state.recipes);

export const getAllRecipes = globalize((state): Recipe[] => {
    return state || [];
});

export const getAllRecipeNames = globalize((state): string[] => {
    return state !== null ? state.map(recipe => recipe.name) : [];
});

export const isRecipesLoaded = globalize((state): boolean => {
    return state !== null;
});

export const getRecipeByName = globalize((state, recipeName: String): Recipe | undefined => {
    if (state && state.length > 0) {
        return state.find(recipe => recipe.name === recipeName);
    } else {
        return undefined;
    }
});

export const getCategories = globalize((state): string[] => {
    return state !== null
        ? state.reduce(
              (accum: string[], recipe) =>
                  accum.includes(recipe.category) ? accum : [...accum, recipe.category],
              ["Kaikki"]
          )
        : [];
});

export const getRecipesWithCategory = globalize((state, category: string): string[] => {
    if (state === null) {
        return [];
    } else if (category === "Kaikki") {
        return state.map(recipe => recipe.name);
    } else {
        return state.filter(recipe => recipe.category === category).map(recipe => recipe.name);
    }
});

export const getAllIngredientNames = globalize((state): string[] => {
    return state !== null
        ? state
              .map(recipe => recipe.ingredients.map(ingredient => ingredient.name))
              .reduce((accum, ingredientlist) => accum.concat(ingredientlist), [] as string[])
              .filter((ingredient, index, array) => {
                  return (
                      array.indexOf(ingredient) === index &&
                      ingredient &&
                      BASIC_INGREDIENTS.indexOf(ingredient) === -1
                  );
              })
        : [];
});

export const getPictureForRecipe = globalize((state, recipeName: string): string | null => {
    if (state === null) {
        return null;
    }
    const recipe = state.find(recipe => recipe.name === recipeName);
    if (recipe !== undefined) {
        return recipe.picture || null;
    } else {
        return null;
    }
});
