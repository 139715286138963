import { RoundToOneDecimal } from "./maths";

const scalableNumberRegExp = new RegExp(/\$[\d\.\,]+/, "g");

export const makeInstructionNumberScaler =
    (multiplier: number) =>
    (instruction: string): string => {
        const scalableNumbers = instruction.matchAll(scalableNumberRegExp);
        let replacedInstruction = instruction;
        for (const numberMatch of scalableNumbers) {
            const normalizedNumber = numberMatch[0].slice(1).replace(",", ".");
            const scaledNumber = RoundToOneDecimal(Number(normalizedNumber) * multiplier);
            replacedInstruction = replacedInstruction.replace(
                numberMatch[0],
                scaledNumber.toString()
            );
        }
        return replacedInstruction;
    };
