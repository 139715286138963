import * as React from "react";
import { ValidationResult } from "./FormValidators";

interface ValidatedInputProps {
    className?: string;
    name?: string;
    type?: string;
    value: string | number;
    onChange: (
        event: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>
    ) => void;
    validators: ((input: string | number) => ValidationResult)[];
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    placeholder?: string;
}

export const ValidatedInput: React.FC<ValidatedInputProps> = props => {
    const { validators, className, ...inputProps } = props;

    const errors = getValidationErrors(validators, props.value);
    const validatedClassName = className.concat(" ", errors ? "invalid" : "valid");

    return (
        <div className="validated-input">
            <input className={validatedClassName} {...inputProps} />
            {!!errors && <div className="validated-input__errors">{errors}</div>}
        </div>
    );
};

export const getValidationErrors = (
    validators: ((input: string | number) => ValidationResult)[],
    input: string | number
) => {
    const errors = validators
        .map(validator => validator(input))
        .filter(result => !result.isValid)
        .map(result => result.message);
    if (errors.length > 0) {
        return errors.join(". ");
    } else {
        return undefined;
    }
};
