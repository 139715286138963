import { UnitName } from "../../utils/unitChanger";
import { makeReducer } from "../utils";
import {
    PORTIONS_MULTIPLIER_SET,
    SET_EDITABLE_INDEX,
    RESET_EDIT_MODE,
    ADD_FILTER,
    RESET_FILTERS,
    REMOVE_FILTER,
    SET_UNIT,
    RESET_UNITS,
} from "./actionTypes";

const initialState = {
    portionMultiplier: 1,
    isInEditMode: false,
    editableIndex: undefined as number | undefined,
    appliedFilters: [] as string[],
    selectedUnits: [] as UnitName[],
};

export const viewStateReducer = makeReducer(initialState, {
    [PORTIONS_MULTIPLIER_SET]: (state, payload) => {
        const { multiplier } = payload;
        return { ...state, portionMultiplier: multiplier };
    },
    [SET_EDITABLE_INDEX]: (state, payload) => {
        const { index } = payload;
        return { ...state, editableIndex: index, isInEditMode: true };
    },
    [RESET_EDIT_MODE]: (state, payload) => {
        return { ...state, editableIndex: undefined, isInEditMode: false };
    },
    [ADD_FILTER]: (state, payload) => {
        return { ...state, appliedFilters: [...state.appliedFilters, payload.filter] };
    },
    [RESET_FILTERS]: (state, payload) => {
        return { ...state, appliedFilters: [] };
    },
    [REMOVE_FILTER]: (state, payload) => {
        const removeIndex = state.appliedFilters.findIndex(filter => filter === payload.filter);
        const filters = [...state.appliedFilters];
        filters.splice(removeIndex, 1);
        return { ...state, appliedFilters: filters };
    },
    [SET_UNIT]: (state, payload) => {
        const { index, unit } = payload;
        const currentUnits = [...state.selectedUnits];
        if (index < currentUnits.length) {
            currentUnits[index] = unit;
        }
        return { ...state, selectedUnits: currentUnits };
    },
    [RESET_UNITS]: (state, payload) => {
        const { ingredientNumber } = payload;
        const units = [];
        for (let i = 0; i < ingredientNumber; i++) {
            units[i] = null;
        }
        return { ...state, selectedUnits: units };
    },
});
