import * as React from "react";
import { RoundTo2Decimals } from "../../utils/maths";
import { Ingredient } from "../../recipe";
import { LoadingSpinner } from "../../commonComponents/loadSpinner";
import { StoreState, selectors, actions, store } from "../../state";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import { setPortionsMultiplierWithPortionsNumber } from "../../state/operations";
import { getAsOtherUnit, getPossibleUnits, UnitName } from "../../utils/unitChanger";
import { classNames } from "../../utils";

interface OwnProps {
    recipeName: string;
}

interface StateProps {
    ingredients: Ingredient[];
    portionMultiplier: number;
    isInEditMode: boolean;
    editableIndex: number;
    originalPortions: number;
    selectedUnits: UnitName[];
}

interface DispatchProps {
    setEditableIndex: (index: number) => void;
    setPortions: (portions: number) => void;
    selectUnit: (index: number, unit: UnitName) => void;
}

type IngredientListProps = OwnProps & StateProps & DispatchProps;

export const IngredientList: React.FC<IngredientListProps> = (props) => {
    if (props.ingredients.length !== 0) {
        return (
            <ul className="ingredient-list">
                {props.ingredients.map((ingredient, index) => (
                    <IngredientListItem
                        key={index}
                        ingredient={ingredient}
                        originalPortions={props.originalPortions}
                        setPortions={props.setPortions}
                        portionMultiplier={props.portionMultiplier}
                        setUnit={(unit) => props.selectUnit(index, unit)}
                        selectedUnit={props.selectedUnits[index]}
                    />
                ))}
            </ul>
        );
    } else {
        return <LoadingSpinner />;
    }
};

const IngredientListItem: React.FC<{
    ingredient: Ingredient;
    originalPortions: number;
    setPortions: (newPortions: number) => void;
    portionMultiplier: number;
    setUnit: (unit: UnitName) => void;
    selectedUnit: UnitName;
}> = ({ ingredient, originalPortions, setPortions, portionMultiplier, setUnit, selectedUnit }) => {
    const inputRef = React.useRef();
    const [isInEditMode, setEditMode] = React.useState(false);

    if (ingredient.subtitle) {
        return <h1 className="ingredient-list__subtitle">{ingredient.subtitle}</h1>;
    }

    const wholeName = [ingredient.prefix, ingredient.name, ingredient.postfix]
        .filter((str) => str !== undefined)
        .join(" ");

    const displayUnit = selectedUnit || ingredient.unit;
    const displayQuantity = getAsOtherUnit(ingredient.unit, ingredient.quantity, displayUnit);

    if (isInEditMode) {
        return (
            <li className="ingredient-list__ingredient-container" onBlur={() => setEditMode(false)}>
                <div className="ingredient-list__ingredient-input">
                    <input
                        className="ingredient-list__ingredient-quantity ingredient-list__ingredient-quantity--input"
                        type="number"
                        min={0}
                        step="any"
                        autoFocus={true}
                        onFocus={(e) => e.target.select()}
                        onChange={(event) => {
                            const newQuantity = getAsOtherUnit(
                                displayUnit,
                                Number(event.target.value),
                                ingredient.unit
                            );

                            const originalQuantity = ingredient.quantity;
                            const portionsOnRecipe = originalPortions || 1;

                            const newPortions = (newQuantity / originalQuantity) * portionsOnRecipe;
                            setPortions(newPortions);
                        }}
                        onKeyPress={(event) => {
                            if (event.key === "Enter") {
                                setEditMode(false);
                            }
                        }}
                        defaultValue={RoundTo2Decimals(displayQuantity * portionMultiplier)}
                        ref={inputRef}
                    />
                    <UnitChanger
                        currentUnit={displayUnit}
                        selectUnit={(newUnit) => {
                            if (inputRef.current !== undefined) {
                                (inputRef.current as any).value =
                                    newUnit === null
                                        ? ingredient.quantity
                                        : RoundTo2Decimals(
                                              getAsOtherUnit(
                                                  ingredient.unit,
                                                  ingredient.quantity,
                                                  newUnit
                                              )
                                          ) * portionMultiplier;
                            }
                            setUnit(newUnit);
                        }}
                    />
                </div>
                <div className="ingredient-list__ingredient-name">{wholeName}</div>
            </li>
        );
    } else {
        return (
            <li className="ingredient-list__ingredient-container">
                <div
                    className="ingredient-list__ingredient-quantity"
                    onClick={() => setEditMode(true)}
                >
                    {ingredient.quantity !== 0 &&
                        RoundTo2Decimals(displayQuantity * portionMultiplier)}{" "}
                    {displayUnit}
                </div>
                <div className="ingredient-list__ingredient-name">{wholeName}</div>
            </li>
        );
    }
};

const UnitChanger: React.FC<{
    currentUnit: string;
    selectUnit: (unit: UnitName) => void;
}> = (props) => {
    const availableUnits = getPossibleUnits(props.currentUnit);
    if (availableUnits.length > 0) {
        return (
            <div className="unit-changer__list">
                {availableUnits.map((unitName) => {
                    const classes = classNames({
                        "unit-changer__list-item": true,
                        "unit-changer__list-item--selected": unitName === props.currentUnit,
                    });
                    return (
                        <div
                            className={classes}
                            key={unitName}
                            onMouseDown={() => props.selectUnit(unitName)}
                        >
                            {unitName}
                        </div>
                    );
                })}
            </div>
        );
    } else {
        return null;
    }
};

const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
    const portionMultiplier = selectors.getPortionsMultiplier(state);
    const recipe = selectors.getRecipeByName(state, ownProps.recipeName);
    const isInEditMode = selectors.getEditMode(state);
    const editableIndex = selectors.getEditableIndex(state);
    const originalPortions = recipe.portions;
    const ingredients = recipe.ingredients;
    const selectedUnits = selectors.getSelectedUnits(state);
    return {
        ingredients,
        isInEditMode,
        editableIndex,
        portionMultiplier,
        originalPortions,
        selectedUnits,
    };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => ({
    setEditableIndex: (index) => {
        dispatch(actions.setEditableIndex(index));
    },
    setPortions: (portions) => {
        setPortionsMultiplierWithPortionsNumber(
            dispatch,
            store.getState(),
            ownProps.recipeName,
            portions
        );
    },
    selectUnit: (index, unit) => {
        dispatch(actions.setUnit(index, unit));
    },
});

export const ConnectedIngredientList = connect(mapStateToProps, mapDispatchToProps)(IngredientList);
