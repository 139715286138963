import * as React from "react";

import { connect } from "react-redux";
import { selectors, StoreState } from "../../state";
import { Link } from "react-router-dom";

interface StateProps {
    tabs: string[];
}

type TabProps = StateProps;

const mapStateToProps = (state: StoreState): TabProps => {
    const tabs = selectors.getTabs(state);
    return {
        tabs,
    };
};

const Tabs: React.FC<TabProps> = props => (
    <div className="tabs__container">
        {props.tabs.map(tab => (
            <Link to={"/resepti/" + tab} className="tabs__tab">
                {tab}
            </Link>
        ))}
    </div>
);

export const ConnectedTabs = connect(
    mapStateToProps,
    undefined
)(Tabs);
