export function setSelectedColorTheme(selectedHue: string, isDark: boolean) {
    takeColorThemeIntoUse(selectedHue, isDark);
    window.localStorage.setItem("selectedHue", selectedHue);
    window.localStorage.setItem("useDarkMode", isDark.toString());
}

export function initializeColorTheme() {
    const selectedHue = getSelectedColorHue();
    const isDark = isUsingDarkMode();
    takeColorThemeIntoUse(selectedHue, isDark);
}

export function takeColorThemeIntoUse(selectedHue: string, isDark: boolean) {
    (document.querySelector(":root") as HTMLElement).style.setProperty(
        "--selected-hue",
        selectedHue
    );
    if (isDark) {
        document.querySelector(":root").classList.toggle("dark", true);
        document.querySelector(":root").classList.toggle("light", false);
    } else {
        document.querySelector(":root").classList.toggle("dark", false);
        document.querySelector(":root").classList.toggle("light", true);
    }
}

export function getSelectedColorHue(): string {
    return window.localStorage.getItem("selectedHue") || "213";
}

export function isUsingDarkMode(): boolean {
    const preference = window.localStorage.getItem("useDarkMode");
    if (preference !== null) {
        return preference === "true";
    } else {
        return window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
}
