import * as React from "react";
import { Ingredient, Recipe } from "../../recipe";
import { ConnectedIngredientList } from "./ingredients";
import { ConnectedInstructionList } from "./instructions";
import { PortionControl } from "./portions";

import { store, selectors, actions, StoreState } from "../../state";
import { connect } from "react-redux";
import { LoadingSpinner } from "../../commonComponents/loadSpinner";
import { setPortionsMultiplierWithPortionsNumber } from "../../state/operations";
import { addToHistory } from "../../utils/historyUtils";
import { ConnectedRecipeViewHeader } from "./recipeViewHeader";
import { Dispatch } from "redux";

interface OwnProps {
    recipeName: string;
}

interface StateProps {
    recipe: Recipe;
    portionMultiplier: number;
    isInEditMode: boolean;
    editableIndex: number;
    portions: number;
    recipesLoaded: boolean;
}

interface DispatchProps {
    setEditableIndex: (index: number) => void;
    setPortions: (portions: number) => void;
    resetEditMode: () => void;
    resetPortions: () => void;
    resetUnits: (ingredients: Ingredient[]) => void;
}

type RecipeViewProps = OwnProps & StateProps & DispatchProps;

const mapStateToProps = (state: StoreState, ownProps: OwnProps) => {
    const recipeName = ownProps.recipeName;
    const recipe = selectors.getRecipeByName(state, recipeName) || new Recipe();
    const portionMultiplier = selectors.getPortionsMultiplier(state);
    const isInEditMode = selectors.getEditMode(state);
    const editableIndex = selectors.getEditableIndex(state);
    const portions = recipe.portions * portionMultiplier;
    const recipesLoaded = selectors.isRecipesLoaded(state);

    return {
        recipe,
        portionMultiplier,
        isInEditMode,
        editableIndex,
        portions,
        recipeName,
        recipesLoaded,
    };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => {
    const recipeName = ownProps.recipeName;
    return {
        setEditableIndex: (index) => {
            dispatch(actions.setEditableIndex(index));
        },
        resetEditMode: () => {
            dispatch(actions.resetEditMode());
        },
        setPortions: (newPortions) => {
            setPortionsMultiplierWithPortionsNumber(
                dispatch,
                store.getState(),
                recipeName,
                newPortions
            );
        },
        resetPortions: () => {
            dispatch(actions.setPortionsMultiplier(1));
        },
        resetUnits: (ingredients) => {
            dispatch(actions.resetUnits(ingredients.length));
        },
    };
};

const RecipeView: React.FC<RecipeViewProps> = (props) => {
    React.useEffect(() => {
        document.title = props.recipeName;
        props.resetPortions();
        addToHistory(props.recipeName);
        props.resetUnits(props.recipe.ingredients);
    }, [props.recipeName]);

    if (!props.recipesLoaded) {
        return <LoadingSpinner />;
    } else {
        return (
            <div className="recipe-details__content">
                <ConnectedRecipeViewHeader recipeName={props.recipeName} />
                <PortionControl portions={props.portions} updatePortions={props.setPortions} />
                <div className="recipe-details__instructions">
                    <ConnectedIngredientList recipeName={props.recipeName} />
                    <ConnectedInstructionList recipeName={props.recipeName} />
                </div>
            </div>
        );
    }
};

export const ConnectedRecipeView = connect(mapStateToProps, mapDispatchToProps)(RecipeView);
