export const CATEGORIES = [
    "Pääruuat",
    "Leivät",
    "Kastikkeet",
    "Lisukkeet",
    "Juomat",
    "Perusohjeet",
    "Makeat",
    "Pikkupurtavaa",
    "Muut",
];
