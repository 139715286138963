/**
 * Lsita aineista joita voi
 * 1. uudelleennimetä
 *  * kun nimi vaihtuu, tarkistetaan onko nimeä jo olemassa ja vaihdetaan quatities siihen
 * 2. Jakaa osiin
 *  * osittetaan raaka-aineeksi ja liitteiksi
 */

import * as React from "react";
import { selectors, StoreState } from "../../state";
import { connect } from "react-redux";
import { changeIngredientData } from "../../services/recipeservice";
import { classNames } from "../../utils";

interface StateProps {
    ingredients: string[];
}

export const IngredientList: React.FC<StateProps> = props => {
    const [selectedIngredient, setSelectedIngredient] = React.useState("");
    const [changedIngredients, setChangedIngredients] = React.useState([] as string[]);
    return (
        <div className="ingredient-list">
            <button
                onClick={() => {
                    processIngredients(props.ingredients).then(window.location.reload);
                }}
            >
                Prosessoi kaikki
            </button>
            {props.ingredients.map((ingredientName, index) =>
                ingredientName === selectedIngredient ? (
                    <ManageIngredientView
                        ingredientName={ingredientName}
                        key={index}
                        onSubmit={() => {
                            setChangedIngredients([...changedIngredients, ingredientName]);
                            setSelectedIngredient("");
                        }}
                    />
                ) : (
                    <IngredientEntry
                        ingredientName={ingredientName}
                        onClick={() => setSelectedIngredient(ingredientName)}
                        key={index}
                        isChanged={changedIngredients.includes(ingredientName)}
                    />
                )
            )}
        </div>
    );
};

const processIngredients = (ingredients: string[]) => {
    return Promise.all(
        ingredients.map(async ingredient => {
            const ingredientParts = ingredient.split(" ");
            if (
                ingredientParts.length === 1 &&
                ingredientParts[0] !== ingredientParts[0].toLowerCase()
            ) {
                await changeIngredientData({
                    originalName: ingredientParts[0],
                    newName: ingredientParts[0].toLowerCase(),
                });
            } else if (ingredientParts.length > 1) {
                const existingIngredient = ingredientParts.findIndex(part =>
                    ingredients.includes(part)
                );
                if (existingIngredient === -1) {
                    if (ingredient !== ingredient.toLowerCase()) {
                        await changeIngredientData({
                            originalName: ingredient,
                            newName: ingredient.toLowerCase(),
                        });
                    }
                } else {
                    const prefix = ingredientParts.slice(0, existingIngredient).join(" ");
                    const newName = ingredientParts[existingIngredient];
                    const postfix = ingredientParts.slice(existingIngredient + 1).join(" ");

                    await changeIngredientData({
                        originalName: ingredient,
                        newName,
                        prefix,
                        postfix,
                    });
                }
            }
        })
    );
};

export const IngredientEntry: React.FC<{
    ingredientName: string;
    onClick: () => void;
    isChanged: boolean;
}> = props => {
    const classes = classNames({
        "ingredient-list__ingredient": true,
        "ingredient-list__ingredient--changed": props.isChanged,
    });
    return (
        <div className={classes} onClick={props.onClick}>
            {props.ingredientName}
        </div>
    );
};

export const ManageIngredientView: React.FC<{
    ingredientName: string;
    onSubmit: () => void;
}> = props => {
    const [prefix, setPrefix] = React.useState("");
    const [newName, setNewName] = React.useState(props.ingredientName);
    const [postfix, setPostfix] = React.useState("");

    return (
        <div className="ingredient-list__manage-ingredient">
            {props.ingredientName}
            <input
                className="ingredient-manager__input"
                value={prefix}
                onChange={event => {
                    setPrefix(event.target.value.toLowerCase());
                }}
            />
            <input
                className="ingredient-manager__input"
                value={newName}
                onChange={event => {
                    setNewName(event.target.value.toLowerCase());
                }}
            />
            <input
                className="ingredient-manager__input"
                value={postfix}
                onChange={event => {
                    setPostfix(event.target.value.toLowerCase());
                }}
            />
            <button
                className="ingredient-manager__submit"
                onClick={() => {
                    changeIngredientData({
                        originalName: props.ingredientName,
                        newName,
                        prefix,
                        postfix,
                    });
                    props.onSubmit();
                }}
            >
                Valmis
            </button>
        </div>
    );
};

const mapStateToProps = (state: StoreState): StateProps => {
    const ingredients = selectors.getAllIngredientNames(state).sort();
    return {
        ingredients,
    };
};

export const ConnectedManageIngredients = connect(mapStateToProps)(IngredientList);
