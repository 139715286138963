import * as React from "react";
import * as ReactDOM from "react-dom";

interface IGenericDialog {
    header?: string;
    message?: string;
}

export const showErrorDialog = (error: IGenericDialog) => {
    const errorHeader = error.header || "Otsikko";
    const errorMessage = error.message || "Tuntematon virhe";

    ReactDOM.render(
        <ErrorDialog header={errorHeader} message={errorMessage} />,
        document.getElementById("modal-root")
    );
};

export const hideDialog = () => {
    ReactDOM.render(null, document.getElementById("modal-root"));
};

const ErrorDialog: React.FC<{ header: string; message: string }> = props => {
    return (
        <div className="modal-dialog__background">
            <div className="error-dialog">
                <div className="error-dialog__header">{props.header}</div>
                <div className="error-dialog__message">{props.message}</div>
                <div className="error-dialog__footer">
                    <div className="error-dialog__footer__buttons">
                        <button className="error-dialog__primary-button" onClick={hideDialog}>
                            Sulje
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface IModalDialog extends IGenericDialog {
    primaryAction: () => void;
}
export const showModalDialog = (props: IModalDialog) => {
    const headerText = props.header || "Imoitus";
    const messageText = props.message || "";
    const primaryAction = props.primaryAction || (() => {});

    ReactDOM.render(
        <ModalDialog header={headerText} message={messageText} primaryAction={primaryAction} />,
        document.getElementById("modal-root")
    );
};

const ModalDialog: React.FC<{
    header: string;
    message: string;
    primaryAction: () => void;
}> = props => {
    const onPrimaryClick = () => {
        props.primaryAction();
        hideDialog();
    };
    return (
        <div className="modal-dialog__background">
            <div className="modal-dialog">
                <div className="modal-dialog__header">{props.header}</div>
                <div className="modal-dialog__message">{props.message}</div>
                <div className="modal-dialog__footer">
                    <div className="modal-dialog__footer__buttons">
                        <button className="modal-dialog__primary-button" onClick={onPrimaryClick}>
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export const Modal: React.FC<{
    header?: string;
    primaryButton?: { action: () => void; text: string };
}> = props => {
    const modalRoot = document.getElementById("modal-root");
    const el = document.createElement("div");
    modalRoot.appendChild(el);

    React.useEffect(() => {
        return () => {
            modalRoot.removeChild(el);
        };
    }, []);

    if (props.header) {
        return ReactDOM.createPortal(
            <div className="modal-dialog__background">
                <div className="modal-dialog">
                    <div className="modal-dialog__header">{props.header}</div>
                    <div className="modal-dialog__message">{props.children}</div>
                    {props.primaryButton && (
                        <div className="modal-dialog__footer">
                            <div className="modal-dialog__footer__buttons">
                                <button
                                    className="modal-dialog__primary-button"
                                    onClick={props.primaryButton.action}
                                >
                                    {props.primaryButton.text}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>,
            el
        );
    } else {
        return ReactDOM.createPortal(
            <div className="modal-dialog__background">
                <div className="modal-dialog">{props.children}</div>
            </div>,
            el
        );
    }
};
