// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

let production = false;
let apiUrl = "http://localhost:80/api";
let testing = false;

if (process.env.NODE_ENV === "production") {
    (production = true), (apiUrl = "https://reseptit.veerola.fi/api");
} else if (process.env.NODE_ENV === "nightly") {
    (production = true), (apiUrl = "https://reseptit-nightly.veerola.fi/api");
} else if (process.env.NODE_ENV === "testing") {
    apiUrl = "http://localhost:8080/api";
    testing = true;
}

export const environment = {
    production: production,
    apiUrl: apiUrl,
    testing: testing,
};
