export const BASIC_INGREDIENTS = ["vettä", "suolaa", "pippuria", "soodaa"];

export const STAPLES = [
    "vehnäjauhoa",
    "vehnäjauhoja",
    "kuivahiivaa",
    "maitoa",
    "öljyä",
    "voita",
    "etikkaa",
    "leivinjauhetta",
    "ruokasoodaa",
    "hunajaa",
    "sokeria",
    "perunajauhoa",
    "kasvislientä",
    "lihalientä",
    "kalalientä",
    "kanalientä",
    "riisiä",
    "kananmunaa",
    "hiivaa",
    "öljyä",
    "maissijauhoa",
    "sinappia",
    "ruisjauhoa",
    "perunajauhoja",
];

export const SUBSTITUTES = Object.freeze({
    papuja: ["papuja", "mustia papuja", "kidneypapuja", "mustapapuja"],
    sipulia: ["sipulia", "salottisipulia", "punasipulia", "isoa sipulia", "kevätsipulia"],
    etikkaa: [
        "etikkaa",
        "riisiviinietikkaa",
        "punaviinietikkaa",
        "valkoviinietikkaa",
        "viinietikkaa",
    ],
    kananmunaa: [
        "kananmunaa",
        "kananmuna",
        "keltuaista",
        "valkuaista",
        "munankeltuaista",
        "munanvalkuaista",
    ],
    kaalia: ["kaalia", "punakaalia", "valkokaalia"],
    sitruunaa: [
        "sitruunaa",
        "sitruunan mehua",
        "sitruunamehua",
        "sitruunan kuorta",
        "sitruunankuorta",
    ],
    valkosipulia: ["valkosipulia", "valkosipulin kynttä", "valkosipulinkynttä"],
    öljyä: ["öljyä", "rypsiöljyä", "oliiviöljyä"],
    hunajaa: ["hunajaa", "juoksevaa hunajaa"],
    tärkkelystä: ["tärkkelystä", "maissijauhoa", "perunajauhoa", "maissijauhoja", "perunajauhoja"],
    paprikaa: ["paprikaa", "vihreää paprikaa", "keltaista paprikaa", "punaista paprikaa"],
    porkkanaa: ["porkkanaa", "porkkana"],
}) as Readonly<{ [key: string]: string[] }>;

export const IGNORED_WORDS = [
    "hienonnettua",
    "rouhittua",
    "viipaleina",
    "kuutioina",
    "tuoretta",
    "jauhettua",
    "viipaloituna",
    "kuorittuna",
    "voiteluun",
    "raastettua",
    "hienonnettuna",
    "suikaloitua",
    "pilkottuna",
    "koristeeksi",
    "hienonnettuja",
    "kokonaista",
    "rouhittuja",
    "iso",
];
