import * as React from "react";
import { setSelectedColorTheme } from "../../utils/colorThemeUtils";

export const COLOR_THEMES = {
    default: "213",
    pink: "320",
    green: "100",
    orange: "25",
    purple: "280",
    red: "0",
} as { [key: string]: string };

export const ColorThemePicker: React.FC<{}> = () => (
    <div className="color-picker__container">
        {Object.entries(COLOR_THEMES).map(([key, value]) => (
            <React.Fragment key={key}>
                <div
                    className="color-picker__color color-picker__color--light"
                    style={{
                        backgroundColor: `hsl(${value}, 80%, 85%)`,
                        borderColor: `hsl(${value}, 80%, 50%)`,
                    }}
                    onClick={() => {
                        const selectedHue = COLOR_THEMES[key] || COLOR_THEMES.default;
                        setSelectedColorTheme(selectedHue, false);
                    }}
                />
                <div
                    className="color-picker__color color-picker__color--dark"
                    style={{
                        backgroundColor: `hsl(${value}, 80%, 15%)`,
                        borderColor: `hsl(${value}, 80%, 50%)`,
                    }}
                    onClick={() => {
                        const selectedHue = COLOR_THEMES[key] || COLOR_THEMES.default;
                        setSelectedColorTheme(selectedHue, true);
                    }}
                />
            </React.Fragment>
        ))}
    </div>
);
