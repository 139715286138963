import * as React from "react";

import { selectors, actions, StoreState } from "../../state";
import { connect } from "react-redux";
import { FavoriteControl } from "./favoriteControl";
//@ts-ignore
import * as Bookmark from "../../../assets/icon-bookmark.inline.svg";
//@ts-ignore
import * as UncheckedBookmark from "../../../assets/icon-bookmark-unchecked.inline.svg";
//@ts-ignore
import * as EditIcon from "../../../assets/icon-edit.inline.svg";
//@ts-ignore
import * as PhotoIcon from "../../../assets/icon-plus.inline.svg";

import { Dispatch } from "redux";
import { environment } from "../../../environments/environment";
import { Modal, showErrorDialog } from "../../commonComponents/modalDialog";
import { Link } from "react-router-dom";

interface OwnProps {
    recipeName: string;
}

interface StateProps {
    isTabbed: boolean;
    isLoggedIn: boolean;
    picture: string | null;
}

interface DispatchProps {
    addTab: () => void;
    removeTab: () => void;
}

type RecipeViewHeaderProps = OwnProps & StateProps & DispatchProps;

const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
    const tabs = selectors.getTabs(state);
    const picture = selectors.getPictureForRecipe(state, ownProps.recipeName);
    return {
        isTabbed: tabs.includes(ownProps.recipeName),
        isLoggedIn: selectors.isLoggedIn(state),
        picture,
    };
};

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps): DispatchProps => {
    const recipeName = ownProps.recipeName;
    return {
        addTab: () => {
            dispatch(actions.addTab(recipeName));
        },
        removeTab: () => {
            dispatch(actions.removeTab(recipeName));
        },
    };
};

const RecipeViewHeader: React.FC<RecipeViewHeaderProps> = props => (
    <div className="recipe-details__header">
        {props.picture && <RecipePicture source={props.picture} />}
        <div className="recipe-details__title-block">
            <div className="recipe-details__name">{props.recipeName}</div>
            <div className="recipe-details__buttons">
                <FavoriteControl recipeName={props.recipeName} />
                <div
                    className="icon recipe-details__icon"
                    onClick={props.isTabbed ? props.removeTab : props.addTab}
                >
                    {props.isTabbed ? <Bookmark /> : <UncheckedBookmark />}
                </div>
                {props.isLoggedIn && <EditRecipeIcon recipeName={props.recipeName} />}
                {props.isLoggedIn && <PictureUploadIcon recipeName={props.recipeName} />}
            </div>
        </div>
    </div>
);

const EditRecipeIcon: React.FC<{ recipeName: string }> = props => {
    return (
        <Link to={"/reseptin-muokkaus/" + props.recipeName}>
            <div className="icon recipe-details__icon">
                <EditIcon />
            </div>
        </Link>
    );
};

const PictureUploadIcon: React.FC<{ recipeName: string }> = props => (
    <>
        <div
            className="icon recipe-details__icon"
            onClick={() => {
                const uploadInput = document.querySelector("#pictureUpload") as HTMLElement;
                if (uploadInput !== null) {
                    uploadInput.click();
                }
            }}
        >
            <PhotoIcon />
        </div>
        <PictureUploadInput {...props} />
    </>
);

const PictureUploadInput: React.FC<{ recipeName: string }> = props => {
    const [progress, setProgress] = React.useState(0);
    return (
        <>
            <input
                className="recipe-details__picture-upload--input"
                id="pictureUpload"
                type="file"
                name="recipeImage"
                accept="image/*"
                onChange={event => {
                    const blob = event.currentTarget.files[0];
                    const formData = new FormData();
                    formData.append("recipeImage", blob);

                    const xmlhttp = new XMLHttpRequest();
                    xmlhttp.onerror = () => {
                        setProgress(0);
                        showErrorDialog({
                            header: "Picture upload error",
                            message: xmlhttp.responseText,
                        });
                    };
                    xmlhttp.onload = () => {
                        setProgress(0);
                        if (xmlhttp.status !== 200) {
                            showErrorDialog({
                                header: "Picture upload error",
                                message: xmlhttp.responseText,
                            });
                        } else {
                            window.location.reload();
                        }
                    };
                    xmlhttp.open(
                        "POST",
                        environment.apiUrl + "/uploads/upload_photo.php?name=" + props.recipeName,
                        true
                    );
                    xmlhttp.upload.onprogress = e => {
                        if (e.lengthComputable) {
                            setProgress((e.loaded / e.total) * 100);
                        }
                    };
                    xmlhttp.send(formData);
                }}
            />
            {progress > 0 && (
                <Modal header="Ladataan kuvaa">
                    <div className="upload-dialog__progress-bar">
                        <div
                            className="upload-dialog__progress-bar--filler"
                            style={{ width: `${progress}%` }}
                        />
                    </div>
                </Modal>
            )}
        </>
    );
};

const RecipePicture: React.FC<{ source: string }> = props => (
    <img src={`/api/uploads/${props.source}`} className="recipe-details__picture" />
);

export const ConnectedRecipeViewHeader = connect(
    mapStateToProps,
    mapDispatchToProps
)(RecipeViewHeader);
