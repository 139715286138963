import { makeReducer } from "../utils";
import { Recipe } from "../../recipe";
import { SET_ALL_RECIPES } from "./actionTypes";

const initialState = null as Recipe[] | null;

export const recipesReducer = makeReducer(initialState, {
    [SET_ALL_RECIPES]: (state, payload) => {
        const { recipes } = payload;
        return recipes;
    },
});
