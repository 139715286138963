import {
    ADD_TAB,
    REMOVE_TAB,
    SET_SEARCH_VALUE,
    SET_LOGGED_IN,
    SET_SEARCH_EXPANDED,
    SET_MENU_EXPANDED,
} from "./actionTypes";

export const addTab = (name: string) => ({ type: ADD_TAB, payload: { name } });

export const removeTab = (name: string) => ({ type: REMOVE_TAB, payload: { name } });

export const setSearchValue = (value: string) => ({ type: SET_SEARCH_VALUE, payload: { value } });

export const setLoggedIn = (value: boolean) => ({ type: SET_LOGGED_IN, payload: { value } });

export const expandSearch = () => ({ type: SET_SEARCH_EXPANDED, payload: { value: true } });
export const collapseSearch = () => ({ type: SET_SEARCH_EXPANDED, payload: { value: false } });

export const expandMenu = () => ({ type: SET_MENU_EXPANDED, payload: { value: true } });
export const collapseMenu = () => ({ type: SET_MENU_EXPANDED, payload: { value: false } });
