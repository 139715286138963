export function addToHistory(recipeName: string) {
    removeFromHistory(recipeName);
    let history: string[] = JSON.parse(window.localStorage.getItem("history"));
    if (history) {
        history.unshift(recipeName);
    } else {
        history = [recipeName];
    }
    window.localStorage.setItem("history", JSON.stringify(history.slice(0, 6)));
}

function removeFromHistory(recipeName: string) {
    let history: string[] = JSON.parse(window.localStorage.getItem("history"));

    if (history && history.indexOf(recipeName) !== -1) {
        history.splice(history.indexOf(recipeName), 1);
    }

    window.localStorage.setItem("history", JSON.stringify(history));
}

export function getHistory(): string[] {
    return JSON.parse(window.localStorage.getItem("history")) || [];
}
