export type UnitName = string;

type Unit = {
    name: string;
    measure: MeasureType;
    isBase: boolean;
    multiplierToBase: number;
};

export const enum MeasureType {
    VOLUME,
    WEIGHT,
}

const UNITS: Unit[] = [
    {
        name: "ml",
        measure: MeasureType.VOLUME,
        isBase: true,
        multiplierToBase: 1,
    },
    {
        name: "tl",
        measure: MeasureType.VOLUME,
        isBase: false,
        multiplierToBase: 5,
    },
    {
        name: "rkl",
        measure: MeasureType.VOLUME,
        isBase: false,
        multiplierToBase: 15,
    },
    {
        name: "dl",
        measure: MeasureType.VOLUME,
        isBase: false,
        multiplierToBase: 100,
    },
    {
        name: "kuppia",
        measure: MeasureType.VOLUME,
        isBase: false,
        multiplierToBase: 284.131,
    },
    {
        name: "l",
        measure: MeasureType.VOLUME,
        isBase: false,
        multiplierToBase: 1000,
    },
    {
        name: "g",
        measure: MeasureType.WEIGHT,
        isBase: true,
        multiplierToBase: 1,
    },
    {
        name: "kg",
        measure: MeasureType.WEIGHT,
        isBase: false,
        multiplierToBase: 1000,
    },
];

export function getAsOtherUnit(startUnit: string, quantity: number, endUnit: string): number {
    if (startUnit === endUnit) {
        return quantity;
    } else {
        const base = getAsBaseUnit(startUnit, quantity);
        return getBaseAsOtherUnit(base.quantity, endUnit);
    }
}

export function getAsBaseUnit(
    unitName: string,
    quantity: number
): { unit: string; quantity: number } {
    const startUnit = getUnitByName(unitName);
    if (startUnit) {
        const baseUnit = getBaseUnitOf(startUnit.name);
        return { unit: baseUnit.name, quantity: quantity * startUnit.multiplierToBase };
    } else {
        return { unit: unitName, quantity: quantity };
    }
}

function getBaseAsOtherUnit(quantity: number, unitName: string): number {
    const unit = getUnitByName(unitName);
    if (unit) {
        return quantity / unit.multiplierToBase;
    } else {
        return -1;
    }
}

export function getBaseUnitOf(unitName: string): Unit {
    const startUnit = getUnitByName(unitName);
    return getBaseUnits().find(unit => unit.measure === startUnit.measure);
}

function getUnitByName(unitName: string): Unit | undefined {
    return UNITS.find(unit => unit.name === unitName);
}

function getBaseUnits(): Unit[] {
    return UNITS.filter(unit => unit.isBase);
}

export function getPossibleUnits(unitName: string) {
    const currentUnit = getUnitByName(unitName);
    if (currentUnit) {
        return UNITS.filter(unit => unit.measure === currentUnit.measure).map(unit => unit.name);
    } else {
        return [];
    }
}
