import {
    SET_CATEGORY,
    SET_COOKING_TIME,
    SET_DESCRIPTION,
    SET_PORTIONS,
    SET_RECIPE_NAME,
} from "./actionTypes";
import { Recipe } from "../../../recipe";
import { INITIALIZE_EDITABLE_RECIPE } from "../actionTypes";

export * from "./instructions/actions";
export * from "./ingredients/actions";

export const setCategory = (category: string) => ({
    type: SET_CATEGORY,
    payload: {
        category,
    },
});

export const setPortions = (portions: number) => ({
    type: SET_PORTIONS,
    payload: {
        portions,
    },
});

export const setRecipeName = (name: string) => ({
    type: SET_RECIPE_NAME,
    payload: {
        name,
    },
});

export const setCoookingTime = (cookingTime: number) => ({
    type: SET_COOKING_TIME,
    payload: {
        cookingTime,
    },
});

export const setDescription = (description: string) => ({
    type: SET_DESCRIPTION,
    payload: {
        description,
    },
});

export const initializeEditableRecipe = (recipe: Recipe) => ({
    type: INITIALIZE_EDITABLE_RECIPE,
    payload: recipe,
});
