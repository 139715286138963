import { StoreState } from "../reducer";
import { makeGlobalizeFunction } from "../utils";

const globalize = makeGlobalizeFunction((state: StoreState) => state.viewState);

export const getEditableIndex = globalize((state): number => {
    return state.editableIndex;
});

export const getEditMode = globalize((state): boolean => {
    return state.editableIndex !== undefined;
});

export const getPortionsMultiplier = globalize((state): number => {
    return state.portionMultiplier;
});

export const getAppliedFilters = globalize((state): string[] => {
    return state.appliedFilters;
});

export const getSelectedUnits = globalize(state => state.selectedUnits);
