import * as React from "react";
import { Link } from "react-router-dom";
import { selectors, StoreState } from "../../state";
import { LoadingSpinner } from "../../commonComponents/loadSpinner";
import { connect } from "react-redux";

interface OwnProps {}

interface StateProps {
    recipes: string[];
    isLoaded: boolean;
}

interface DispatchProps {}

type RecipeListProps = OwnProps & StateProps & DispatchProps;

function mapStateToProps(state: StoreState): StateProps {
    const recipes = selectors.getAllRecipeNames(state);
    const isLoaded = selectors.isRecipesLoaded(state);
    return {
        recipes,
        isLoaded,
    };
}

export const ManageRecipes: React.FC<RecipeListProps> = props => {
    if (!props.isLoaded) {
        return <LoadingSpinner />;
    } else {
        return (
            <ul className="recipe-list">
                <ManageRecipesListItems recipes={props.recipes} />
            </ul>
        );
    }
};

export const ConnectedManageRecipes = connect(
    mapStateToProps,
    undefined
)(ManageRecipes);

export const ManageRecipesListItems: React.FC<{ recipes: string[] }> = props => (
    <>
        {props.recipes.map((name, i) => (
            <li className="recipelist-item" key={i}>
                <Link to={"/reseptin-muokkaus/" + name} className="recipelist-item__link">
                    {name}
                </Link>
            </li>
        ))}
    </>
);
