import { SUBSTITUTES, IGNORED_WORDS } from "./filteringConstants";
import { Recipe } from "../../../recipe";

export const reduceIngredients = (allIngredients: string[]): string[] => {
    const strippedIngredients = allIngredients.map(ingredient =>
        stripIngredientName(findSubstitute(ingredient))
    );
    return removeDuplicateValues(strippedIngredients);
};

export const removeDuplicateValues = <type>(array: type[]): type[] => {
    return array.reduce(
        (newArray, value) => (newArray.includes(value) ? newArray : [value, ...newArray]),
        [] as type[]
    );
};

export const findSubstitute = (ingredient: string): string => {
    for (const substitute in SUBSTITUTES) {
        if (SUBSTITUTES[substitute].includes(ingredient)) {
            return substitute;
        }
    }
    return ingredient;
};

export const stripIngredientName = (word: string): string => {
    const splitted = word.split(" ");
    if (splitted.length === 1) {
        return word;
    } else {
        for (const ignoreWord of IGNORED_WORDS) {
            if (splitted.indexOf(ignoreWord) !== -1) {
                splitted.splice(splitted.indexOf(ignoreWord), 1);
            }
        }
        return splitted.join(" ");
    }
};

export const filterRecipes = (filters: string[], recipes: Recipe[]): string[] => {
    const availableRecipes = recipes.filter(recipe => {
        const ingredients = recipe.ingredients
            .map(ingredient => ingredient.name)
            .filter(ingredientName => !!ingredientName)
            .map(ingredientName => findSubstitute(stripIngredientName(ingredientName)));

        return filters.every(filter => ingredients.indexOf(filter) !== -1);
    });
    return availableRecipes.map(recipe => recipe.name);
};
