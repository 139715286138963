import { makeReducer } from "../../../utils";
import {
    SET_INSTRUCTIONS,
    REMOVE_INSTRUCTION,
    MOVE_INSTRUCTION_UP,
    MOVE_INSTRUCTION_DOWN,
    ADD_INSTRUCTION,
    SET_INSTRUCTION,
} from "./actionTypes";
import { INITIALIZE_EDITABLE_RECIPE } from "../../actionTypes";
import { Recipe } from "../../../../recipe";

export const instructionsReducer = makeReducer([] as string[], {
    [INITIALIZE_EDITABLE_RECIPE]: (state, payload: Recipe) => {
        const { instructions } = payload;
        return [...instructions];
    },
    [SET_INSTRUCTIONS]: (state, payload) => {
        const { instructions } = payload;
        return instructions;
    },
    [SET_INSTRUCTION]: (state, payload) => {
        const { index: editIndex, instruction: value } = payload;
        return state.map((instruction, index) => {
            if (index === editIndex) {
                return value;
            } else {
                return instruction;
            }
        });
    },
    [ADD_INSTRUCTION]: (state, payload) => {
        return state.concat("");
    },
    [REMOVE_INSTRUCTION]: (state, payload) => {
        const { index: removeIndex } = payload;
        return state.filter((_, index) => index !== removeIndex);
    },
    [MOVE_INSTRUCTION_UP]: (state, payload) => {
        const { index: moveIndex } = payload;
        if (moveIndex === 0) {
            return state;
        } else {
            return state.reduce(
                (newState, instruction, index) => {
                    if (index === moveIndex - 1) {
                        return [...newState, state[moveIndex]];
                    } else if (index === moveIndex) {
                        return [...newState, state[moveIndex - 1]];
                    } else {
                        return [...newState, instruction];
                    }
                },
                [] as string[]
            );
        }
    },
    [MOVE_INSTRUCTION_DOWN]: (state, payload) => {
        const { index: moveIndex } = payload;
        if (moveIndex === state.length - 1) {
            return state;
        } else {
            return state.reduce(
                (newState, instruction, index) => {
                    if (index === moveIndex + 1) {
                        return [...newState, state[moveIndex]];
                    } else if (index === moveIndex) {
                        return [...newState, state[moveIndex + 1]];
                    } else {
                        return [...newState, instruction];
                    }
                },
                [] as string[]
            );
        }
    },
});
