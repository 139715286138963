import { makeGlobalizeFunction } from "../utils";
import { StoreState } from "../reducer";

const globalize = makeGlobalizeFunction((state: StoreState) => state.appState);

export const getTabs = globalize((state): string[] => {
    return state.tabs;
});

export const getSearchValue = globalize((state): string => {
    return state.searchValue;
});

export const isLoggedIn = globalize((state): boolean => {
    return state.isLoggedIn;
});

export const isSearchExpanded = globalize((state): boolean => {
    return state.isSearchExpanded;
});

export const isMenuExpanded = globalize((state): boolean => {
    return state.isMenuExpanded;
});
