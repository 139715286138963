export function RoundToOneDecimal(x: number): number {
    return decimalRound(x, 1);
}

export function RoundTo2Decimals(x: number): number {
    return decimalRound(x, 2);
}

export function RoundToQuarters(x: number): number {
    return Math.round(x * 4) / 4;
}

function decimalRound(x: number, decimals: number): number {
    const multiplier = Math.pow(10, decimals);
    return Math.round(x * multiplier) / multiplier;
}
