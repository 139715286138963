import * as React from "react";

// @ts-ignore
import * as SearchIcon from "../../../assets/icon-search.inline.svg";
import { connect } from "react-redux";
import { selectors, store, actions, StoreState } from "../../state";
import { SearchResults } from "./searchResults";

interface StateProps {
    recipeNames: string[];
    searchValue: string;
    isExpanded: boolean;
}

interface OwnProps {}

interface DispachProps {
    setSearchValue: (value: string) => void;
    collapseSearch: () => void;
}

type SearchProps = StateProps & OwnProps;

const mapStateToProps = (state: StoreState, ownProps: OwnProps): SearchProps => {
    const recipeNames = selectors.getAllRecipeNames(state);
    const searchValue = selectors.getSearchValue(state);
    const isExpanded = selectors.isSearchExpanded(state);
    return {
        isExpanded,
        recipeNames,
        searchValue,
        ...ownProps,
    };
};

const mapDispatchToProps = (): DispachProps => ({
    setSearchValue: (value) => {
        store.dispatch(actions.setSearchValue(value));
    },
    collapseSearch: () => store.dispatch(actions.collapseSearch()),
});

export const Search: React.FC<SearchProps & StateProps & DispachProps> = (props) => {
    const searchInput = React.createRef<HTMLInputElement>();

    React.useEffect(() => {
        if (props.isExpanded) {
            searchInput.current.focus();
        }
    }, [props.isExpanded]);

    return (
        <div className={`search search--${props.isExpanded ? "expanded" : "collapsed"}`}>
            <div className="search__header">
                <input
                    className="search__input"
                    value={props.searchValue}
                    onChange={(event) => props.setSearchValue(event.target.value)}
                    placeholder="Hae reseptejä..."
                    ref={searchInput}
                />
                <button className="search__close-button" onClick={props.collapseSearch}>
                    Sulje
                </button>
            </div>
            <SearchResults
                recipeNames={props.recipeNames}
                searchValue={props.searchValue}
                toggleExpanded={props.collapseSearch}
            />
        </div>
    );
};

export const ConnectedSearch = connect(mapStateToProps, mapDispatchToProps)(Search);
