import { Recipe } from "../recipe";
import { getRecipe } from "../services/recipeservice";

export function isCached(recipeName: string): boolean {
    return !!window.localStorage.getItem(recipeName);
}

export function cacheRecipe(recipe: Recipe) {
    window.localStorage.setItem(recipe.name, JSON.stringify(recipe));
}

export async function cacheRecipeByName(recipeName: string) {
    const recipe = await getRecipe(recipeName);
    cacheRecipe(recipe);
}

export function getRecipeFromCache(recipeName: string) {
    return JSON.parse(window.localStorage.getItem(recipeName));
}

export function removeRecipeFromCache(recipeName: string) {
    window.localStorage.removeItem(recipeName);
}
