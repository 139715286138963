/**
 * Created by Eero Saarinen (eero.saarinen@hotmail.fi)
 * Created under Creative Commons licence
 */

import { environment } from "../../environments/environment";

export function login(password: string) {
    return new Promise<void>((resolve, reject) => {
        const xmlhttp = new XMLHttpRequest();
        xmlhttp.onload = () => {
            if (xmlhttp.status === 200) {
                resolve();
            } else {
                reject();
            }
        };
        xmlhttp.onerror = () => reject(xmlhttp.statusText);
        xmlhttp.open("POST", environment.apiUrl + "/login.php", true);
        xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xmlhttp.send(`password=${password}`);
    });
}

export function checkAuthentication() {
    if (environment.testing) {
        return new Promise((resolve, reject) => {
            if (document.cookie.includes("testToken")) {
                resolve(true);
            } else {
                reject(false);
            }
        }) as Promise<boolean>;
    }
    return new Promise((resolve, _reject) => {
        const xmlhttp = new XMLHttpRequest();
        xmlhttp.onload = () => {
            if (xmlhttp.status === 200) {
                resolve(true);
            } else {
                resolve(false);
            }
        };
        xmlhttp.onerror = () => resolve(false);
        xmlhttp.open("POST", environment.apiUrl + "/check_token.php", true);
        xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xmlhttp.send();
    }) as Promise<boolean>;
}
