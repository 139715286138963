import {
    SET_INSTRUCTION,
    SET_INSTRUCTIONS,
    REMOVE_INSTRUCTION,
    MOVE_INSTRUCTION_UP,
    MOVE_INSTRUCTION_DOWN,
    ADD_INSTRUCTION,
} from "./actionTypes";

export const setInstructions = (instructions: string[]) => ({
    type: SET_INSTRUCTIONS,
    payload: {
        instructions,
    },
});

export const setInstruction = (index: number, instruction: string) => ({
    type: SET_INSTRUCTION,
    payload: {
        index,
        instruction,
    },
});

export const removeInstruction = (index: number) => ({
    type: REMOVE_INSTRUCTION,
    payload: {
        index,
    },
});

export const moveInstructionUp = (index: number) => ({
    type: MOVE_INSTRUCTION_UP,
    payload: {
        index,
    },
});

export const moveInstructionDown = (index: number) => ({
    type: MOVE_INSTRUCTION_DOWN,
    payload: {
        index,
    },
});

export const addInstruction = () => ({
    type: ADD_INSTRUCTION,
    payload: {},
});
