import * as React from "react";
import { login, checkAuthentication } from "../services/authService";
import { store, actions, StoreState, selectors } from "../state";
import { connect } from "react-redux";

interface OwnProps {
    element: React.ReactElement;
}

interface StateProps {
    isLoggedIn: boolean;
}

type Props = OwnProps & StateProps;

export const PrivateRoute: React.FC<Props> = (props) => {
    const [isLoaded, setIsLoaded] = React.useState(false);
    React.useEffect(() => {
        updateLoginStatus().finally(() => {
            setIsLoaded(true);
        });
    }, []);

    if (isLoaded) {
        if (props.isLoggedIn) {
            return props.element;
        } else {
            return <LoginPage />;
        }
    } else {
        return null;
    }
};

const updateLoginStatus = async () => {
    try {
        const isValid = await checkAuthentication();
        store.dispatch(actions.setLoggedIn(isValid));
    } catch (error) {
        store.dispatch(actions.setLoggedIn(false));
    }
};

const LoginPage: React.FC<{}> = (props) => {
    const [isFirstTry, setIsFirstTry] = React.useState(true);

    return (
        <div className="content">
            <div className="login-header">Anna salasana reseptien muokkaukseen</div>
            <div className="login-page__content">
                <input
                    id="password-input"
                    type="password"
                    className={isFirstTry ? "password-input" : "password-input incorrect-password"}
                    onKeyPress={(event) => {
                        if (event.key === "Enter") {
                            handleLogin(() => setIsFirstTry(false));
                        }
                    }}
                />
                {isFirstTry ? null : (
                    <div className="login-incorrect-password-text">Virheellinen salasana</div>
                )}
                <button
                    className="submitbutton login"
                    onClick={() => handleLogin(() => setIsFirstTry(false))}
                >
                    Kirjaudu
                </button>
            </div>
        </div>
    );
};

const handleLogin = async (onFail: () => void) => {
    const password = (document.getElementById("password-input") as HTMLInputElement).value;
    try {
        await login(password);
        store.dispatch(actions.setLoggedIn(true));
    } catch (error) {
        store.dispatch(actions.setLoggedIn(false));
        onFail();
    }
};

const mapStateToProps = (state: StoreState, ownProps: OwnProps): StateProps => {
    const isLoggedIn = selectors.isLoggedIn(state);
    return {
        isLoggedIn,
    };
};

export const ConnectedPrivateRoute = connect(mapStateToProps, undefined)(PrivateRoute);
