import { cacheRecipeByName, removeRecipeFromCache } from "./cacheUtils";

export function toggleFavorite(recipeName: string) {
    if (isFavorite(recipeName)) {
        removeFromFavorites(recipeName);
    } else {
        addToFavorites(recipeName);
    }
}

export function isFavorite(recipeName: string): boolean {
    const favorites = JSON.parse(window.localStorage.getItem("favorites"));
    return favorites && favorites.indexOf(recipeName) !== -1;
}

function addToFavorites(recipeName: string) {
    let favorites = JSON.parse(window.localStorage.getItem("favorites"));
    if (favorites) {
        favorites.push(recipeName);
    } else {
        favorites = [recipeName];
    }
    window.localStorage.setItem("favorites", JSON.stringify(favorites));
    cacheRecipeByName(recipeName);
}

function removeFromFavorites(recipeName: string) {
    let favorites = JSON.parse(window.localStorage.getItem("favorites"));

    if (favorites && favorites.indexOf(recipeName) !== -1) {
        favorites.splice(favorites.indexOf(recipeName), 1);
    }

    window.localStorage.setItem("favorites", JSON.stringify(favorites));
    removeRecipeFromCache(recipeName);
}

export function getFavorites(): string[] {
    return JSON.parse(window.localStorage.getItem("favorites")) || [];
}
