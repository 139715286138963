/**
 * Created by Eero Saarinen (eero.saarinen@hotmail.fi)
 * Created under Creative Commons licence
 */

import { Recipe } from "../../recipe";
import { environment } from "../../../environments/environment";
import { showErrorDialog } from "../../commonComponents/modalDialog";
import { BASIC_INGREDIENTS } from "../../components/filtering/utils/filteringConstants";
import { isCached, getRecipeFromCache } from "../../utils/cacheUtils";

export function getRecipes(): Promise<Recipe[]> {
    return new Promise((resolve, reject) => {
        const xmlhttp = new XMLHttpRequest();
        xmlhttp.onload = () => {
            resolve(parseRecipeResponse(xmlhttp.responseText));
        };
        xmlhttp.onerror = () => reject(xmlhttp.statusText);
        xmlhttp.open("GET", environment.apiUrl + "/get_recipes.php", true);
        xmlhttp.send();
    }) as Promise<Recipe[]>;
}

export function parseRecipeResponse(data: string): Recipe[] {
    const rawResponse = JSON.parse(data);
    if (rawResponse.length > 0) {
        return rawResponse.map(
            (rawRecipe: any): Recipe => ({
                ...rawRecipe,
                portions: Number(rawRecipe.portions),
                cookingTime: Number(rawRecipe.cookingTime),
                ingredients: rawRecipe.ingredients.map((rawIngredient: any) => ({
                    ...rawIngredient,
                    quantity: Number(rawIngredient.quantity),
                })),
            })
        );
    } else {
        throw new Error("Could not parse recipes");
    }
}

export function putRecipe(data: Recipe): any {
    return new Promise((resolve, reject) => {
        const xmlhttp = new XMLHttpRequest();
        xmlhttp.onload = () => {
            if (xmlhttp.status === 200) {
                resolve(true);
            } else {
                reject(false);
            }
        };
        xmlhttp.onerror = () => reject(xmlhttp.statusText);
        xmlhttp.open("POST", environment.apiUrl + "/post_recipe.php", true);
        xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xmlhttp.send(`json_data=${encodeURIComponent(JSON.stringify(data))}`);
    });
}

export function editRecipe(data: Recipe) {
    return new Promise((resolve, reject) => {
        const xmlhttp = new XMLHttpRequest();
        xmlhttp.onload = () => {
            if (xmlhttp.status === 200) {
                resolve(true);
            } else {
                reject(false);
            }
        };
        xmlhttp.onerror = () => reject(xmlhttp.statusText);
        xmlhttp.open("POST", environment.apiUrl + "/update_recipe.php", true);
        xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xmlhttp.send(
            `name=${encodeURIComponent(data.name)}&json_data=${encodeURIComponent(
                JSON.stringify(data)
            )}`
        );
    });
}

export function deleteRecipe(name: string) {
    return new Promise((resolve, reject) => {
        const xmlhttp = new XMLHttpRequest();
        xmlhttp.onload = () => {
            if (xmlhttp.status === 200) {
                resolve(true);
            } else {
                reject(false);
            }
        };
        xmlhttp.onerror = () => reject(xmlhttp.statusText);
        xmlhttp.open("DELETE", environment.apiUrl + "/delete_recipe.php?name=" + name, true);
        xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        xmlhttp.send();
    });
}

export async function getAllIngredients() {
    const recipes = await getRecipes();
    const allIngredients = recipes.map(recipe =>
        recipe.ingredients.map(ingredient => ingredient.name)
    );
    return allIngredients
        .reduce((accum, ingredientlist) => accum.concat(ingredientlist), [] as string[])
        .filter((ingredient, index, array) => {
            return (
                array.indexOf(ingredient) === index &&
                ingredient &&
                BASIC_INGREDIENTS.indexOf(ingredient) === -1
            );
        });
}

export async function getRecipeNames(): Promise<string[]> {
    const recipes = await getRecipes();
    return recipes.map(recipe => recipe.name);
}

export function getCategories(): Promise<string[]> {
    return getRecipes()
        .then(recipes => {
            let categories = ["Kaikki"];
            for (let i = 0; i < recipes.length; i++) {
                if (categories.indexOf(recipes[i].category) === -1) {
                    categories.push(recipes[i].category);
                }
            }
            return categories;
        })
        .catch(handleError);
}

export function getRecipesWithCategory(category: string): Promise<Recipe[]> {
    return getRecipes()
        .then(recipes => {
            if (category === "Kaikki") {
                return recipes;
            }
            let wantedRecipes = [];
            for (let i = 0; i < recipes.length; i++) {
                if (recipes[i].category === category) {
                    wantedRecipes.push(recipes[i]);
                }
            }
            return wantedRecipes;
        })
        .catch(handleError);
}

export async function getRecipe(name: string) {
    try {
        const recipes = await getRecipes();
        return recipes.find(recipe => recipe.name === name);
    } catch (error) {
        if (isCached(name)) {
            return getRecipeFromCache(name);
        } else {
            handleError(error);
        }
    }
}

function handleError(error: any): Promise<any> {
    console.log("virhe tapahtui", error);
    error = {
        header: "Virhe!",
        message: "Reseptiserveriin ei saatu yhteyttä, yritä hetken päästä uudestaan.",
    };
    showErrorDialog(error);
    return Promise.reject(error.message || error);
}

export const changeIngredientData = (data: {
    originalName: string;
    prefix?: string;
    newName: string;
    postfix?: string;
}): Promise<boolean> => {
    return new Promise((resolve, reject) => {
        const xmlhttp = new XMLHttpRequest();
        xmlhttp.onload = () => {
            if (xmlhttp.status === 200) {
                resolve(true);
            } else {
                reject(false);
            }
        };
        xmlhttp.onerror = () => reject(xmlhttp.statusText);
        xmlhttp.open("POST", environment.apiUrl + "/change_ingredient_data.php", true);
        xmlhttp.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
        let postData = `originalName=${encodeURIComponent(
            data.originalName
        )}&newName=${encodeURIComponent(data.newName)}`;
        if (data.prefix) {
            postData += `&prefix=${encodeURIComponent(data.prefix)}`;
        }
        if (data.postfix) {
            postData += `&postfix=${encodeURIComponent(data.postfix)}`;
        }

        xmlhttp.send(postData);
    });
};
